import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import styles from './AddHorseBtn.module.scss';

const AddHorseBtn = ({ onClick }) => {
  const { t } = useTranslation('messages');

  return (
    <div className={styles.wrapper}>
      <button
        type="button"
        title={t('actions.addHorse')}
        onClick={onClick}
        className={styles.btn}
      >
        <span className={styles.plusIcon} />
        {t('actions.addHorse')}
      </button>
    </div>
  );
};

export default AddHorseBtn;

AddHorseBtn.propTypes = {
  onClick: PropTypes.func,
};

AddHorseBtn.defaultProps = {
  onClick: null,
};
