import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import styles from './Button.module.scss';

export const Button = ({
  type,
  label,
  variant,
  fullwidth,
  wide,
  size,
  isLoading,
  onClick,
  ...rest
}) => (
  <button
    type={type}
    onClick={onClick}
    className={cx(
      styles.btn,
      fullwidth && styles.fullwidth,
      wide && styles.wide,
      isLoading && styles.isLoading,
      styles[variant],
      styles[size],
    )}
    {...rest}
  >
    <span>{label}</span>
  </button>
);

Button.propTypes = {
  type: PropTypes.string,
  label: PropTypes.string,
  variant: PropTypes.oneOf([
    'primary',
    'secondary',
    'link',
    'primary-outline',
    'secondary-outline',
  ]),
  fullwidth: PropTypes.bool,
  wide: PropTypes.bool,
  size: PropTypes.oneOf(['', 'lg']),
  isLoading: PropTypes.bool,
  onClick: PropTypes.func,
};

Button.defaultProps = {
  type: 'button',
  label: '',
  variant: 'primary',
  fullwidth: false,
  wide: false,
  size: '',
  isLoading: false,
  onClick: null,
};
