import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import styles from './SyncingMsg.module.scss';

const SyncingMsg = () => {
  const { t } = useTranslation('messages');
  const { isSyncing } = useSelector((state) => state.uiReducer);

  return isSyncing ? (
    <div className={styles.wrapper}>{t('syncingMsg')}</div>
  ) : null;
};

export default SyncingMsg;
