import React from 'react';
import { Link } from 'react-router-dom';
import cx from 'classnames';
import PropTypes from 'prop-types';
import styles from './Button.module.scss';

export const ButtonIconLink = ({ title, icon, variant, shadow, ...rest }) => (
  <Link
    className={cx(styles.btnIcon, styles[variant], shadow && styles.hasShadow)}
    title={title}
    {...rest}
  >
    <i className={icon} />
  </Link>
);

ButtonIconLink.propTypes = {
  title: PropTypes.string,
  icon: PropTypes.string,
  variant: PropTypes.oneOf([
    'primary',
    'secondary',
    'primary-outline',
    'secondary-outline',
  ]),
  shadow: PropTypes.bool,
};

ButtonIconLink.defaultProps = {
  title: '',
  icon: '',
  variant: 'primary',
  shadow: false,
};
