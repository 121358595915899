import React from 'react';
import PropTypes from 'prop-types';
import styles from './AuthForm.module.scss';

export const FormPrivacyRow = ({ children }) => (
  <div className={styles.privacyRow}>{children}</div>
);

FormPrivacyRow.propTypes = {
  children: PropTypes.node,
};

FormPrivacyRow.defaultProps = {
  children: null,
};
