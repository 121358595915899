import React from 'react';
import PropTypes from 'prop-types';
import { I18nextProvider } from 'react-i18next';
import i18next from 'i18next';
import messagesEn from 'src/translations/en/messages.json';
import messagesDe from 'src/translations/de/messages.json';
import ChainedBackend from 'i18next-chained-backend';
import HttpBackend from 'i18next-http-backend';
import LocalStorageBackend from 'i18next-localstorage-backend';

i18next.use(ChainedBackend).init({
  fallbackLng: 'en',
  interpolation: { escapeValue: false },
  lng: 'en',
  resources: {
    en: { messages: messagesEn },
    de: { messages: messagesDe },
  },

  backend: {
    backends: [LocalStorageBackend, HttpBackend],
    backendOptions: [
      {
        expirationTime: 100 * 24 * 60 * 60 * 1000,
      },
      {
        loadPath: 'src/translations/{{lng}}/messages.json',
      },
    ],
  },
});

i18next.on('languageChanged', (lng) => {
  document.documentElement.setAttribute('lang', lng);
});

document.documentElement.lang = i18next.language;

const LocalProvider = ({ children }) => (
  <I18nextProvider i18n={i18next}>{children}</I18nextProvider>
);

LocalProvider.propTypes = {
  children: PropTypes.node,
};

LocalProvider.defaultProps = {
  children: null,
};

export default LocalProvider;
