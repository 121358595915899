import React from 'react';
import PropTypes from 'prop-types';
import Slider from 'react-slick';
import styles from './Slider.module.scss';

const settings = {
  dots: true,
  infinite: true,
  arrows: false,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 3,
  appendDots: (dots) => <Dots dots={dots} />,
  customPaging: (i) => <Dot dot={i} />,
  autoplaySpeed: 3000,
  autoplay: true,
  responsive: [
    {
      breakpoint: 820,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
      },
    },
    {
      breakpoint: 620,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

export const SliderWrapper = ({ children }) => (
  <div className={styles.wrapper}>
    <Slider {...settings}>{children}</Slider>
  </div>
);

SliderWrapper.propTypes = {
  children: PropTypes.node,
};

SliderWrapper.defaultProps = {
  children: null,
};

const Dots = ({ dots }) => <ul className={styles.dotsWrapper}>{dots}</ul>;

Dots.propTypes = {
  dots: PropTypes.node,
};

Dots.defaultProps = {
  dots: null,
};

const Dot = ({ dot, ...rest }) => (
  <button type="button" className={styles.dot} {...rest}>
    {dot}
  </button>
);

Dot.propTypes = {
  dot: PropTypes.number,
};

Dot.defaultProps = {
  dot: 0,
};
