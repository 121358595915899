import React from 'react';
import PropTypes from 'prop-types';
import TopBar from 'src/components/TopBar';
import Modals from 'src/components/Modals';
import PullToRefresh from 'src/components/PullToRefresh';
import CookieNotice from 'src/uikit/CookieNotice';
import OfflineMsg from 'src/uikit/OfflineMsg';
import SyncingMsg from 'src/uikit/SyncingMsg';
import SuccessToast from 'src/uikit/SuccessToast';
import styles from './ReportLayout.module.scss';

export * from './ReportHeader';
export * from './ReportFooter';

const ReportLayout = ({ children }) => (
  <div className={styles.wrapper}>
    <PullToRefresh />
    <OfflineMsg />
    <SyncingMsg />
    <SuccessToast />
    <TopBar />
    <div className="container">{children}</div>
    <Modals />
    <CookieNotice />
  </div>
);

export default ReportLayout;

ReportLayout.propTypes = {
  children: PropTypes.node,
};

ReportLayout.defaultProps = {
  children: null,
};
