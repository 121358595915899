import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import cx from 'classnames';
import styles from './NewReportModal.module.scss';

export const NewReportModalHeader = ({
  horseName,
  date,
  canCopy,
  onCopy,
  copyLabel,
  scrollProgress,
}) => {
  const { t } = useTranslation('messages');

  return (
    <div className={styles.header}>
      <div className={styles.headerPrimary}>
        <div className={styles.horse}>
          <i className="icon-horse" />
          {horseName}
        </div>

        {date && <div className={styles.headerDate}>{date}</div>}
      </div>

      <div className={cx(styles.headerSecondary, canCopy && styles.withCopy)}>
        <span className={styles.headerSecondaryLine} />

        {canCopy && (
          <button
            type="button"
            className={styles.headerCopyBtn}
            onClick={onCopy}
          >
            {copyLabel}
          </button>
        )}
      </div>

      <div
        className={cx(
          styles.scrollProgressContainer,
          canCopy && styles.withCopy,
        )}
      >
        <div
          className={styles.scrollProgress}
          style={{ width: `${scrollProgress}%` }}
        />

        <span className={styles.scrollLabel}>
          {scrollProgress <= 26 && '1'}
          {scrollProgress > 26 && scrollProgress <= 62 && '2'}
          {scrollProgress > 62 && scrollProgress <= 82 && '3'}
          {scrollProgress > 82 && scrollProgress <= 94 && '4'}
          {scrollProgress > 94 && '5'}
          {t('of5')}
        </span>
      </div>
    </div>
  );
};

NewReportModalHeader.propTypes = {
  horseName: PropTypes.string,
  date: PropTypes.node,
  canCopy: PropTypes.bool,
  onCopy: PropTypes.func,
  copyLabel: PropTypes.string,
  scrollProgress: PropTypes.number,
};

NewReportModalHeader.defaultProps = {
  horseName: '',
  date: null,
  canCopy: false,
  onCopy: null,
  copyLabel: '',
  scrollProgress: 0,
};
