import AuthService from 'src/services/authService';
import { getRecord, storeRecord, deleteRow } from 'src/utils/dbUtils';
import {
  AUTH_SET_INITIALIZED,
  AUTH_SET_USER,
  AUTH_LOGIN_USER,
  AUTH_LOGOUT_USER,
  AUTH_SET_ERRORS,
  AUTH_RESET_ERRORS,
} from './actionTypes';

export const authGetUser = () => (dispatch) => {
  if (!navigator.onLine) {
    return getRecord('users', 1)
      .then((res) =>
        res
          ? dispatch({
              type: AUTH_SET_USER,
              payload: res,
            })
          : null,
      )
      .catch((err) =>
        dispatch({
          type: AUTH_SET_ERRORS,
          payload: err,
        }),
      )
      .finally(() =>
        dispatch({
          type: AUTH_SET_INITIALIZED,
        }),
      );
  }

  return AuthService.getUser()
    .then((res) => {
      storeRecord('users', { ...res.data?.user, id: 1 });

      dispatch({
        type: AUTH_SET_USER,
        payload: res.data,
      });
    })
    .catch((err) =>
      dispatch({
        type: AUTH_SET_ERRORS,
        payload: err.response,
      }),
    )
    .finally(() =>
      dispatch({
        type: AUTH_SET_INITIALIZED,
      }),
    );
};

export const loginUser = (data) => (dispatch) => {
  AuthService.loginUser(data)
    .then((res) => {
      storeRecord('users', { ...res.data?.user, id: 1 });

      return dispatch({
        type: AUTH_LOGIN_USER,
        payload: res.data,
      });
    })
    .catch((err) =>
      dispatch({
        type: AUTH_SET_ERRORS,
        payload: err.response,
      }),
    );
};

export const registerUser = (data) => (dispatch) => {
  AuthService.registerUser(data)
    .then((res) => {
      storeRecord('users', { ...res.data?.user, id: 1 });

      return dispatch({
        type: AUTH_LOGIN_USER,
        payload: res.data,
      });
    })
    .catch((err) =>
      dispatch({
        type: AUTH_SET_ERRORS,
        payload: err.response,
      }),
    );
};

export const logoutUser = () => (dispatch) => {
  if (!navigator.onLine) {
    localStorage.setItem('logout', true);

    return deleteRow('users', 1)
      .catch((err) =>
        dispatch({
          type: AUTH_SET_ERRORS,
          payload: err,
        }),
      )
      .finally(() => {
        dispatch({
          type: AUTH_LOGOUT_USER,
        });
      });
  }

  return AuthService.logoutUser()
    .then(() => deleteRow('users', 1))
    .catch((err) =>
      dispatch({
        type: AUTH_SET_ERRORS,
        payload: err.response,
      }),
    )
    .finally(() => {
      localStorage.removeItem('logout');

      dispatch({
        type: AUTH_LOGOUT_USER,
      });
    });
};

export const authForgotPassword = (data, onSuccess) => (dispatch) => {
  AuthService.forgotPassword(data)
    .then((res) => {
      if (onSuccess) onSuccess(res.data);

      return true;
    })
    .catch((err) =>
      dispatch({
        type: AUTH_SET_ERRORS,
        payload: err.response,
      }),
    );
};

export const authResetPassword = (data, onSuccess) => (dispatch) => {
  AuthService.resetPassword(data)
    .then((res) => {
      if (onSuccess) onSuccess(res.data);

      return true;
    })
    .catch((err) =>
      dispatch({
        type: AUTH_SET_ERRORS,
        payload: err.response,
      }),
    );
};

export const updateUserPassword = (data, onSuccess) => (dispatch) => {
  AuthService.updatePassword(data)
    .then((res) => {
      if (onSuccess) onSuccess(res.data);

      return true;
    })
    .catch((err) =>
      dispatch({
        type: AUTH_SET_ERRORS,
        payload: err.response,
      }),
    );
};

export const resetAuthErrors = () => (dispatch) => {
  dispatch({
    type: AUTH_RESET_ERRORS,
  });
};
