import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';
import i18n from 'i18next';
import { registerUser, resetAuthErrors } from 'src/store/actions/authActions';
import useForm from 'src/hooks/useForm';
import {
  LoginLayout,
  Heading,
  DescriptionText,
  IntroBlock,
  Promo,
} from 'src/layouts/LoginLayout';
import { Button } from 'src/uikit/Button';
import TextInput from 'src/uikit/TextInput';
import Select from 'src/uikit/Select';
import {
  FormWrapper,
  FormNote,
  FormItem,
  FormFooter,
  FormPrivacyRow,
} from 'src/uikit/AuthForm';
import Checkbox from 'src/uikit/Checkbox';
import OfflineNotice from 'src/uikit/OfflineNotice';
import { countriesEn, countriesDe } from 'src/constants/countries';
import { initialFieldValues } from './constants';

const SignupPage = () => {
  const { t } = useTranslation('messages');
  const currentLang = i18n.language;
  const dispatch = useDispatch();
  const { errors } = useSelector((state) => state.authReducer);
  const { mode } = useSelector((state) => state.uiReducer);
  const { values, handleStateChange, getErrorMsg } = useForm({
    ...initialFieldValues,
  });
  const [inputErrors, setInputErrors] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const countries = currentLang === 'en' ? countriesEn : countriesDe;

  const handleSubmit = (e) => {
    e.preventDefault();

    setIsSubmitting(true);
    setInputErrors(null);

    const formData = JSON.stringify({ ...values, locale: i18n.language });

    dispatch(registerUser(formData));
  };

  useEffect(() => {
    setIsSubmitting(false);
    setInputErrors(errors?.errors);
  }, [errors]);

  useEffect(
    () => () => {
      dispatch(resetAuthErrors());
    },
    [],
  );

  return (
    <LoginLayout>
      <div className="container">
        <IntroBlock>
          <Heading heading={t('signupPage.title')} variant="small" />
          <DescriptionText>
            <Trans
              t={t}
              i18nKey="signupPage.intro"
              components={{
                loginLink: <Link to="/login" />,
              }}
            />
          </DescriptionText>

          <FormWrapper>
            {mode === 'offline' && (
              <div className="mb-16">
                <OfflineNotice />
              </div>
            )}

            <form onSubmit={handleSubmit} noValidate>
              <FormItem>
                <TextInput
                  type="text"
                  name="firstname"
                  id="firstname"
                  defaultValue=""
                  onChange={(e) =>
                    handleStateChange('firstname', e.target.value)
                  }
                  label={t('login.nameLabel')}
                  error={getErrorMsg(inputErrors, 'firstname')}
                  disabled={mode === 'offline'}
                />
              </FormItem>

              <FormItem>
                <TextInput
                  type="text"
                  name="lastname"
                  id="lastname"
                  defaultValue=""
                  onChange={(e) =>
                    handleStateChange('lastname', e.target.value)
                  }
                  label={t('login.lastnameLabel')}
                  error={getErrorMsg(inputErrors, 'lastname')}
                  disabled={mode === 'offline'}
                />
              </FormItem>

              <FormItem>
                <Select
                  name="country"
                  id="country"
                  defaultValue=""
                  onChange={(e) => handleStateChange('country', e.target.value)}
                  label={t('login.countryLabel')}
                  error={getErrorMsg(inputErrors, 'country')}
                  tooltip={t('login.countryTooltip')}
                  disabled={mode === 'offline'}
                >
                  <option value="" />

                  {countries.map((country) => (
                    <option key={country.id} value={country.id}>
                      {country.label}
                    </option>
                  ))}
                </Select>
              </FormItem>

              <FormItem>
                <TextInput
                  type="email"
                  name="email"
                  id="email"
                  defaultValue=""
                  onChange={(e) => handleStateChange('email', e.target.value)}
                  label={t('login.emailLabel')}
                  error={getErrorMsg(inputErrors, 'email')}
                  disabled={mode === 'offline'}
                />
              </FormItem>

              <FormItem>
                <TextInput
                  type="password"
                  name="password"
                  id="password"
                  defaultValue=""
                  onChange={(e) =>
                    handleStateChange('password', e.target.value)
                  }
                  label={t('login.passwordLabel')}
                  tooltip={
                    <span
                      dangerouslySetInnerHTML={{
                        __html: t('login.passwordStrength', {
                          interpolation: { escapeValue: false },
                        }),
                      }}
                    />
                  }
                  error={getErrorMsg(inputErrors, 'password')}
                  disabled={mode === 'offline'}
                />
              </FormItem>

              <FormPrivacyRow>
                <Trans
                  t={t}
                  i18nKey="signupPage.privacyPolicy"
                  components={{
                    privacyLink: <Link to="/privacy-policy" target="_blank" />,
                  }}
                />
              </FormPrivacyRow>

              <FormNote>
                <p
                  dangerouslySetInnerHTML={{
                    __html: t('login.marketingNote', {
                      interpolation: { escapeValue: false },
                    }),
                  }}
                />
              </FormNote>

              <FormItem>
                <Checkbox
                  label={t('login.marketingLabel')}
                  onChange={(e) =>
                    handleStateChange('marketing', e.target.checked)
                  }
                  disabled={mode === 'offline'}
                />
              </FormItem>

              <Promo>
                <p
                  dangerouslySetInnerHTML={{
                    __html: t('login.promoText', {
                      interpolation: { escapeValue: false },
                    }),
                  }}
                />
              </Promo>

              <FormFooter>
                <Button
                  type="submit"
                  variant="primary"
                  label={t('login.signupBtnLabel')}
                  isLoading={isSubmitting}
                  disabled={mode === 'offline'}
                />
              </FormFooter>
            </form>
          </FormWrapper>
        </IntroBlock>
      </div>
    </LoginLayout>
  );
};

export default SignupPage;
