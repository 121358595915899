import React from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { uiShowModal } from 'src/store/actions/uiActions';
import { Button } from 'src/uikit/Button';
import styles from './HorseListPlaceholder.module.scss';

const HorseListPlaceholder = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation('messages');

  const handleNewHorse = () =>
    dispatch(
      uiShowModal({
        type: 'HORSE_EDIT_MODAL',
      }),
    );

  return (
    <div className={styles.wrapper}>
      <div className={styles.msg}>{t('horseListPlaceholder.msg')}</div>
      <div className={styles.footer}>
        <Button
          type="button"
          variant="secondary"
          label={t('actions.addNewLabel')}
          onClick={handleNewHorse}
        />
      </div>
    </div>
  );
};

export default HorseListPlaceholder;
