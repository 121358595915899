import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';
import { loginUser, resetAuthErrors } from 'src/store/actions/authActions';
import useForm from 'src/hooks/useForm';
import {
  LoginLayout,
  Heading,
  DescriptionText,
  IntroBlock,
} from 'src/layouts/LoginLayout';
import { Button, ButtonLink } from 'src/uikit/Button';
import TextInput from 'src/uikit/TextInput';
import { FormWrapper, FormItem, FormFooter } from 'src/uikit/AuthForm';
import OfflineNotice from 'src/uikit/OfflineNotice';
import { initialFieldValues } from './constants';

const LoginPage = () => {
  const { t } = useTranslation('messages');
  const dispatch = useDispatch();
  const { errors } = useSelector((state) => state.authReducer);
  const { mode } = useSelector((state) => state.uiReducer);
  const { values, handleStateChange, getErrorMsg } = useForm(
    initialFieldValues,
  );
  const [inputErrors, setInputErrors] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();

    setIsSubmitting(true);
    setInputErrors(null);

    const formData = JSON.stringify(values);

    dispatch(loginUser(formData));
  };

  useEffect(() => {
    setIsSubmitting(false);
    setInputErrors(errors?.errors);
  }, [errors]);

  useEffect(
    () => () => {
      dispatch(resetAuthErrors());
    },
    [],
  );

  return (
    <LoginLayout>
      <div className="container">
        <IntroBlock>
          <Heading heading={t('loginPage.title')} variant="secondary" />
          <DescriptionText>
            <Trans
              t={t}
              i18nKey="loginPage.intro"
              components={{
                signupLink: <Link to="/sign-up" />,
              }}
            />
          </DescriptionText>

          <FormWrapper>
            {mode === 'offline' && (
              <div className="mb-16">
                <OfflineNotice />
              </div>
            )}

            <form onSubmit={handleSubmit} noValidate>
              <FormItem>
                <TextInput
                  type="email"
                  name="email"
                  id="email"
                  defaultValue=""
                  onChange={(e) => handleStateChange('email', e.target.value)}
                  label={t('login.emailLabel')}
                  error={getErrorMsg(inputErrors, 'email')}
                  disabled={mode === 'offline'}
                />
              </FormItem>

              <FormItem>
                <TextInput
                  type="password"
                  name="password"
                  id="password"
                  defaultValue=""
                  onChange={(e) =>
                    handleStateChange('password', e.target.value)
                  }
                  label={t('login.passwordLabel')}
                  error={getErrorMsg(inputErrors, 'password')}
                  comment={
                    <ButtonLink
                      variant="link"
                      to="/forgot-password"
                      label={t('login.forgotPasswordBtnLabel')}
                    />
                  }
                  disabled={mode === 'offline'}
                />
              </FormItem>

              <FormFooter>
                <Button
                  type="submit"
                  variant="primary"
                  label={t('login.loginBtnLabel')}
                  isLoading={isSubmitting}
                  disabled={mode === 'offline'}
                />
              </FormFooter>
            </form>
          </FormWrapper>
        </IntroBlock>
      </div>
    </LoginLayout>
  );
};

export default LoginPage;
