import React, { useState } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import styles from './CookieNotice.module.scss';

const Accordion = ({ children, title }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className={cx(styles.accordion, isOpen && styles.isOpen)}>
      <button
        type="button"
        className={styles.accordionHeader}
        onClick={() => setIsOpen(!isOpen)}
      >
        <span className={styles.accordionTitle}>{title}</span>
      </button>

      <div className={styles.accordionBody}>
        <div className={styles.accordionContent}>{children}</div>
      </div>
    </div>
  );
};

export default Accordion;

Accordion.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string,
};

Accordion.defaultProps = {
  children: null,
  title: '',
};
