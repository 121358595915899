import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  authResetPassword,
  resetAuthErrors,
} from 'src/store/actions/authActions';
import useForm from 'src/hooks/useForm';
import {
  LoginLayout,
  Heading,
  DescriptionText,
  IntroBlock,
} from 'src/layouts/LoginLayout';
import { Button, ButtonLink } from 'src/uikit/Button';
import TextInput from 'src/uikit/TextInput';
import { FormWrapper, FormItem, FormFooter } from 'src/uikit/AuthForm';
import ErrorNotice from 'src/uikit/ErrorNotice';
import { initialFieldValues } from './constants';

const PasswordResetPage = () => {
  const { t } = useTranslation('messages');
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const { errors } = useSelector((state) => state.authReducer);
  const { values, handleStateChange, getErrorMsg } = useForm(
    initialFieldValues,
  );
  const [inputErrors, setInputErrors] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSuccess, setIsSuccess] = useState();
  const email = searchParams.get('email');
  const token = searchParams.get('token');

  const handleSuccess = () => {
    setIsSubmitting(false);
    setIsSuccess(true);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    setIsSubmitting(true);
    setInputErrors(null);

    const formData = JSON.stringify({ ...values, token, email });

    dispatch(authResetPassword(formData, handleSuccess));
  };

  useEffect(() => {
    setIsSubmitting(false);
    setInputErrors(errors?.errors);
  }, [errors]);

  useEffect(
    () => () => {
      dispatch(resetAuthErrors());
    },
    [],
  );

  return (
    <LoginLayout>
      <div className="container">
        <IntroBlock>
          {isSuccess ? (
            <>
              <Heading
                heading={t('resetPasswordPage.titleSuccess')}
                variant="small"
              />
              <FormFooter>
                <ButtonLink
                  to="/login"
                  variant="primary"
                  label={t('login.loginBtnLabel')}
                />
              </FormFooter>
            </>
          ) : (
            <>
              <Heading
                heading={t('resetPasswordPage.title')}
                variant="secondary"
              />
              <DescriptionText>{t('resetPasswordPage.intro')}</DescriptionText>
              <FormWrapper>
                {inputErrors &&
                  ('email' in inputErrors || 'token' in inputErrors) && (
                    <ErrorNotice>{t('errors.NOT_VALID_LINK')}</ErrorNotice>
                  )}

                <form onSubmit={handleSubmit} noValidate>
                  <FormItem>
                    <TextInput
                      type="password"
                      name="password"
                      id="password"
                      defaultValue=""
                      onChange={(e) =>
                        handleStateChange('password', e.target.value)
                      }
                      label={t('login.newPasswordLabel')}
                      tooltip={
                        <span
                          dangerouslySetInnerHTML={{
                            __html: t('login.passwordStrength', {
                              interpolation: { escapeValue: false },
                            }),
                          }}
                        />
                      }
                      error={getErrorMsg(inputErrors, 'password')}
                    />
                  </FormItem>

                  <FormFooter>
                    <Button
                      type="submit"
                      variant="primary"
                      label={t('login.passwordResetBtnLabel')}
                      fullwidth
                      isLoading={isSubmitting}
                    />
                  </FormFooter>
                </form>
              </FormWrapper>
            </>
          )}
        </IntroBlock>
      </div>
    </LoginLayout>
  );
};

export default PasswordResetPage;
