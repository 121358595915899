import { useState } from 'react';
import { useTranslation } from 'react-i18next';

const useForm = (initialFieldValues) => {
  const { t } = useTranslation('messages');
  const [values, setValues] = useState(initialFieldValues);

  const handleStateChange = (key, value) => {
    setValues((initState) => ({
      ...initState,
      [key]: value,
    }));
  };

  const getErrorMsg = (errorsObj, key) =>
    errorsObj && key in errorsObj ? t(`errors.${errorsObj[key][0]}`) : '';

  return {
    values,
    setValues,
    handleStateChange,
    getErrorMsg,
  };
};

export default useForm;
