import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import cx from 'classnames';
import moment from 'moment';
import { Link } from 'react-scroll';
import { uiShowModal } from 'src/store/actions/uiActions';
import { symptoms } from 'src/constants/symptoms';
import TableWrapper from './TableWrapper';
import styles from './SymptomsReportList.module.scss';

const DAYS = 42;
const COL_WIDTH = 62;

const SymptomsReportList = ({ report, preview }) => {
  const { t } = useTranslation('messages');
  const dispatch = useDispatch();

  const dateList = [...Array(DAYS).keys()].reduce((acc, i) => {
    const date = moment(report.min_date).add(i, 'days').format('YYYY-MM-DD');

    const record = report.records.find((rec) => rec.date === date) || {
      date,
      symptoms: [],
    };

    return [...acc, { ...record }];
  }, []);

  const handleEditRecord = (date) => {
    const record = report.records.find((rec) => rec.date === date) || null;

    dispatch(
      uiShowModal({
        type: record ? 'RECORD_EDIT_MODAL' : 'RECORD_NEW_MODAL',
        horseName: report.horse.name,
        date,
        ...(record ? { recordId: record.id } : { reportId: report.id }),
      }),
    );
  };

  useEffect(() => {
    moment.updateLocale('en', {
      week: {
        dow: 1,
      },
    });
  }, []);

  return (
    <div>
      <div className={styles.nav}>
        <div className={styles.navInner}>
          {symptoms.map((symptomGroup) => (
            <Link
              key={symptomGroup.title}
              id={`nav_${symptomGroup.title}`}
              activeClass={styles.isActive}
              to={symptomGroup.title}
              spy={false}
              smooth
              offset={-20}
              duration={300}
              className={styles.navLink}
              isDynamic
            >
              <span>{t(`symptoms.${symptomGroup.title}`)}</span>
            </Link>
          ))}
        </div>
      </div>

      <TableWrapper>
        <table className={styles.listTable}>
          <colgroup>
            <col className={styles.titleCellGroup} />

            {[...Array(DAYS).keys()].map((day) => (
              <col key={day} width={COL_WIDTH} />
            ))}
          </colgroup>

          <thead>
            <tr>
              <th className={styles.tableTitleCell}>&nbsp;</th>

              {dateList.map((day) => (
                <DateBtn
                  key={day.date}
                  day={day}
                  handleEditRecord={handleEditRecord}
                  preview={preview}
                />
              ))}
            </tr>
          </thead>

          <tbody>
            {symptoms.map((symptomGroup) => (
              <React.Fragment key={symptomGroup.title}>
                <tr id={symptomGroup.title}>
                  <td className={styles.tableTitleCell}>
                    <span className={styles.tableSectionTitle}>
                      {t(`symptoms.${symptomGroup.title}`)}
                    </span>
                  </td>
                  <td colSpan={DAYS} />
                </tr>

                {symptomGroup.symptoms.map((symptom) => (
                  <tr key={symptom}>
                    <td className={styles.tableTitleCell}>
                      <span className={styles.tableItemTitle}>
                        {t(`symptoms.${symptom}`)}
                      </span>
                    </td>

                    {dateList.map((day) => (
                      <td key={day.date} className={styles.tableCell}>
                        <i
                          className={cx(
                            'icon-checkmark',
                            styles.checkmark,
                            day.symptoms.includes(symptom) && styles.isActive,
                          )}
                        />
                      </td>
                    ))}
                  </tr>
                ))}
              </React.Fragment>
            ))}
          </tbody>
        </table>
      </TableWrapper>
    </div>
  );
};

export default SymptomsReportList;

SymptomsReportList.propTypes = {
  report: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    min_date: PropTypes.string,
    records: PropTypes.arrayOf(PropTypes.object),
    horse: PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      name: PropTypes.string,
    }),
  }),
  preview: PropTypes.bool,
};

SymptomsReportList.defaultProps = {
  report: {},
  preview: false,
};

const DateBtn = ({ day, handleEditRecord, preview }) => {
  const { t } = useTranslation('messages');
  const dayRef = useRef();

  useEffect(() => {
    if (day.date !== moment().format('YYYY-MM-DD')) return;

    dayRef.current.scrollIntoView({
      block: 'nearest',
      inline: 'end',
    });
  }, [day]);

  return (
    <th key={day.date} className={styles.tableCell} ref={dayRef}>
      <span className={styles.dayLabel}>
        {t(`day.${moment(day.date).weekday()}`)}
      </span>
      {preview ? (
        <div
          className={cx(
            styles.dateBtn,
            !!day.symptoms.length && styles.hasValue,
          )}
        >
          <span className={styles.dateLabel}>
            {moment(day.date).format('DD')}
          </span>
          <span className={styles.monthLabel}>
            {t(`month.${moment(day.date).format('M')}`)}
          </span>
        </div>
      ) : (
        <button
          type="button"
          className={cx(
            styles.dateBtn,
            !!day.symptoms.length && styles.hasValue,
          )}
          onClick={() => handleEditRecord(day.date)}
          disabled={moment(day.date).isAfter(moment())}
        >
          <span className={styles.dateLabel}>
            {moment(day.date).format('DD')}
          </span>
          <span className={styles.monthLabel}>
            {t(`month.${moment(day.date).format('M')}`)}
          </span>
        </button>
      )}
    </th>
  );
};

DateBtn.propTypes = {
  day: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    date: PropTypes.string,
    symptoms: PropTypes.arrayOf(PropTypes.string),
  }),
  handleEditRecord: PropTypes.func,
  preview: PropTypes.bool,
};

DateBtn.defaultProps = {
  day: {},
  handleEditRecord: null,
  preview: false,
};
