import HorseService from 'src/services/horseService';
import {
  getRecords,
  storeRecords,
  storeRecord,
  deleteRow,
} from 'src/utils/dbUtils';
import {
  HORSES_GET_ALL,
  HORSES_CREATE,
  HORSES_UPDATE,
  HORSES_DELETE,
  HORSES_SET_ERRORS,
  HORSES_RESET_ERRORS,
  HORSES_RESET_ALL,
} from './actionTypes';

export const getAllHorses = (query) => (dispatch) => {
  if (!navigator.onLine) {
    return getRecords('horses')
      .then((res) =>
        dispatch({
          type: HORSES_GET_ALL,
          payload: { data: res.sort((a, b) => b.id - a.id) },
        }),
      )
      .catch((err) =>
        dispatch({
          type: HORSES_SET_ERRORS,
          payload: err,
        }),
      );
  }

  return HorseService.getAll(query)
    .then((res) => {
      storeRecords('horses', res.data?.data);

      dispatch({
        type: HORSES_GET_ALL,
        payload: res.data,
      });
    })
    .catch((err) =>
      dispatch({
        type: HORSES_SET_ERRORS,
        payload: err.response,
      }),
    );
};

export const createHorse = (data, onSuccess) => (dispatch) => {
  HorseService.create(data)
    .then((res) => {
      if (onSuccess) onSuccess(res.data);

      storeRecord('horses', res.data);

      return dispatch({
        type: HORSES_CREATE,
        payload: res.data,
      });
    })
    .catch((err) =>
      dispatch({
        type: HORSES_SET_ERRORS,
        payload: err.response,
      }),
    );
};

export const updateHorse = (id, data, onSuccess) => (dispatch) => {
  HorseService.update(id, data)
    .then((res) => {
      if (onSuccess) onSuccess(res.data);

      storeRecord('horses', res.data);

      return dispatch({
        type: HORSES_UPDATE,
        payload: res.data,
      });
    })
    .catch((err) =>
      dispatch({
        type: HORSES_SET_ERRORS,
        payload: err.response,
      }),
    );
};

export const deleteHorse = (id, onSuccess) => (dispatch) => {
  HorseService.destroy(id)
    .then((res) => {
      if (onSuccess) onSuccess(res.data);

      deleteRow('horses', id);

      return dispatch({
        type: HORSES_DELETE,
        payload: res.data,
      });
    })
    .catch((err) =>
      dispatch({
        type: HORSES_SET_ERRORS,
        payload: err.response,
      }),
    );
};

export const resetHorses = () => (dispatch) => {
  dispatch({
    type: HORSES_RESET_ALL,
  });
};

export const resetHorsesErrors = () => (dispatch) => {
  dispatch({
    type: HORSES_RESET_ERRORS,
  });
};
