import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { uiShowModal } from 'src/store/actions/uiActions';
import { getAllHorses, resetHorses } from 'src/store/actions/horseActions';
import MainLayout from 'src/layouts/MainLayout';
import Logo from 'src/uikit/Logo';
import HorseList from 'src/uikit/HorseList';
import HorseCard from 'src/uikit/HorseCard';
import AddHorseBtn from 'src/uikit/AddHorseBtn';
import PageLoader from 'src/uikit/PageLoader';
import HorseListPlaceholder from 'src/components/HorseListPlaceholder';

const DiaryPage = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation('messages');
  const { horses } = useSelector((state) => state.horseReducer);
  const [isLoading, setIsLoading] = useState(true);

  const handleNewHorse = () => {
    dispatch(
      uiShowModal({
        type: 'HORSE_EDIT_MODAL',
      }),
    );
  };

  useEffect(() => {
    if (!horses || !horses.data) return;

    setIsLoading(false);
  }, [horses]);

  useEffect(() => {
    dispatch(getAllHorses());

    return () => {
      dispatch(resetHorses());
    };
  }, []);

  return (
    <MainLayout>
      <Logo />
      <h1 className="main-heading mt-24">{t('diaryPage.title')}</h1>

      <div className="container">
        {isLoading ? (
          <PageLoader />
        ) : (
          <HorseList>
            {!horses.data?.length && <HorseListPlaceholder />}

            {horses.data?.map((horse) => (
              <HorseCard key={horse.id} horse={horse} />
            ))}

            {!!horses.data?.length && <AddHorseBtn onClick={handleNewHorse} />}
          </HorseList>
        )}
      </div>
    </MainLayout>
  );
};

export default DiaryPage;
