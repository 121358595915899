import React from 'react';
import PropTypes from 'prop-types';
import { Navigate, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import PageLoader from 'src/uikit/PageLoader';

const PrivateRoute = ({ component: Component, ...rest }) => {
  const location = useLocation();
  const { isInitialized, isAuthenticated } = useSelector(
    (state) => state.authReducer,
  );

  if (!isInitialized) return <PageLoader />;

  if (!isAuthenticated)
    return <Navigate to="/" state={{ from: location }} replace />;

  return <Component {...rest} />;
};

export default PrivateRoute;

PrivateRoute.propTypes = {
  component: PropTypes.elementType,
};

PrivateRoute.defaultProps = {
  component: null,
};
