import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import { uiCloseModal, uiShowSuccessToast } from 'src/store/actions/uiActions';
import { createReport } from 'src/store/actions/reportActions';
import useForm from 'src/hooks/useForm';
import useReportScroll from 'src/hooks/useReportScroll';
import {
  NewReportModalWrapper,
  NewReportModalHeader,
  NewReportModalFooter,
  NewReportModalBtnDown,
} from 'src/uikit/NewReportModal';
import ReportFormList from 'src/uikit/ReportFormList';
import { Button } from 'src/uikit/Button';
import DatePicker from 'src/uikit/DatePicker';
import { symptoms } from 'src/constants/symptoms';

const ReportNewModal = ({ modal }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation('messages');
  const { horse } = modal;
  const { values, handleStateChange } = useForm({
    horse_id: horse.id,
    date: moment().format('YYYY-MM-DD'),
    symptoms: [],
  });
  const { scrolled, wrapperRef, scrollProgress } = useReportScroll();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleClose = () => dispatch(uiCloseModal());

  const onSuccess = (report) => {
    navigate(`/reports/${report.id}`);

    handleClose();

    dispatch(uiShowSuccessToast());
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    setIsSubmitting(true);

    const formData = JSON.stringify(values);

    dispatch(createReport(formData, onSuccess));
  };

  const handleScrollDown = () => {
    const windowHeight = window.innerHeight;
    wrapperRef.current.scrollBy(0, windowHeight / 2);
  };

  return (
    <NewReportModalWrapper
      title={t('reportNewModal.title')}
      onClose={handleClose}
      forwardRef={wrapperRef}
      onScroll={scrollProgress}
    >
      <form onSubmit={handleSubmit} noValidate>
        <NewReportModalHeader
          horseName={horse.name}
          date={
            <DatePicker
              defaultDate={moment().format('YYYY-MM-DD')}
              onChange={(val) => handleStateChange('date', val)}
              maxDate={moment().format('YYYY-MM-DD')}
            />
          }
          scrollProgress={scrolled}
        />

        <ReportFormList
          symptoms={symptoms}
          onChange={(val) => handleStateChange('symptoms', val)}
        />

        <NewReportModalFooter>
          {scrolled < 90 && (
            <NewReportModalBtnDown onClick={handleScrollDown} />
          )}

          <Button
            type="submit"
            variant="primary"
            label={t('actions.done')}
            fullwidth
            isLoading={isSubmitting}
          />
        </NewReportModalFooter>
      </form>
    </NewReportModalWrapper>
  );
};

export default ReportNewModal;

ReportNewModal.propTypes = {
  modal: PropTypes.shape({
    type: PropTypes.string,
    horse: PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      name: PropTypes.string,
    }),
  }),
};

ReportNewModal.defaultProps = {
  modal: {},
};
