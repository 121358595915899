import React from 'react';
import PropTypes from 'prop-types';
import styles from './NewReportModal.module.scss';

export const NewReportModalBtnDown = ({ onClick }) => (
  <button type="button" className={styles.listDownBtn} onClick={onClick}>
    Down
  </button>
);

NewReportModalBtnDown.propTypes = {
  onClick: PropTypes.func,
};

NewReportModalBtnDown.defaultProps = {
  onClick: () => {},
};
