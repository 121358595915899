import React from 'react';
import PropTypes from 'prop-types';
import styles from './LoginLayout.module.scss';

export const AuthBtnBlock = ({ children }) => (
  <div className={styles.authBtnBlock}>{children}</div>
);

AuthBtnBlock.propTypes = {
  children: PropTypes.node,
};

AuthBtnBlock.defaultProps = {
  children: null,
};
