import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { uiShowModal } from 'src/store/actions/uiActions';
import { Button, ButtonIcon, ButtonIconLink } from 'src/uikit/Button';
import styles from './HorseCard.module.scss';

const HorseCard = ({ horse }) => {
  const dispatch = useDispatch();
  const { mode } = useSelector((state) => state.uiReducer);
  const { t } = useTranslation('messages');

  const handleAddReport = () =>
    dispatch(
      uiShowModal({
        type: 'REPORT_NEW_MODAL',
        horse,
      }),
    );

  const handleEdit = () =>
    dispatch(
      uiShowModal({
        type: 'HORSE_EDIT_MODAL',
        horse,
      }),
    );

  const handleDelete = () =>
    dispatch(
      uiShowModal({
        type: 'HORSE_DELETE_MODAL',
        horse,
      }),
    );

  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        <div className={styles.header}>
          <div className={styles.headerMain}>
            <h3 className={styles.title}>{horse.name}</h3>
            <div className={styles.age}>
              {t('horse.years', { count: horse.age })}
            </div>
          </div>

          <div className={styles.actions}>
            <ButtonIcon
              variant="primary-outline"
              title={t('actions.delete')}
              icon="icon-trash"
              onClick={handleDelete}
            />
            <ButtonIcon
              variant="secondary-outline"
              title={t('actions.edit')}
              icon="icon-edit"
              onClick={handleEdit}
            />
          </div>
        </div>

        <div className={styles.reportList}>
          {horse.reports?.map((report) => (
            <div key={report.id} className={styles.reportItem}>
              <div className={styles.reportDates}>
                <div className={styles.reportDate}>
                  <span className={styles.reportDateLabel}>
                    {t('horse.dateFrom')}:
                  </span>
                  <span className={styles.reportDateValue}>
                    {moment(report.min_date).format('DD.MM.YYYY')}
                  </span>
                </div>
                <div className={styles.reportDate}>
                  <span className={styles.reportDateLabel}>
                    {t('horse.dateTo')}:
                  </span>
                  <span className={styles.reportDateValue}>
                    {moment(report.min_date)
                      .add(41, 'days')
                      .format('DD.MM.YYYY')}
                  </span>
                </div>
              </div>

              <div className={styles.reportSymptoms}>
                <div className={styles.reportSymptomsLabel}>
                  {t('horse.symptomsLabel')}:{' '}
                  <Tooltip text={t('horse.symptomsTooltip')} />
                </div>
                <div className={styles.reportSymptomsCount}>
                  <strong>{report.symptoms_count}</strong>/22
                </div>
              </div>

              <div className={styles.reportActions}>
                <ButtonIconLink
                  variant="primary"
                  shadow
                  title={t('actions.showMore')}
                  icon="icon-arrow-right"
                  to={`/reports/${report.id}`}
                />
              </div>
            </div>
          ))}
        </div>

        <div className={styles.footer}>
          <Button
            variant="secondary-outline"
            label={t('actions.newReport')}
            fullwidth
            onClick={handleAddReport}
            disabled={mode === 'offline'}
          />
        </div>
      </div>
    </div>
  );
};

export default HorseCard;

HorseCard.propTypes = {
  horse: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    name: PropTypes.string,
    age: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    reports: PropTypes.arrayOf(PropTypes.object),
  }),
};

HorseCard.defaultProps = {
  horse: {},
};

const Tooltip = ({ text }) => (
  <span className={styles.tooltip}>
    <i className="icon-info" />
    <span className={styles.tooltipContainer}>{text}</span>
  </span>
);

Tooltip.propTypes = {
  text: PropTypes.string,
};

Tooltip.defaultProps = {
  text: '',
};
