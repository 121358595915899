import React from 'react';
import PropTypes from 'prop-types';
import styles from './LoginLayout.module.scss';

export const DescriptionText = ({ children }) => (
  <p className={styles.dscrText}>{children}</p>
);

DescriptionText.propTypes = {
  children: PropTypes.node,
};

DescriptionText.defaultProps = {
  children: null,
};
