import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';

export const usePageTitle = (title) => {
  const { t } = useTranslation('messages');
  const currentLang = i18n.language;

  useEffect(() => {
    const oldTitle = document.title;

    if (title && title !== oldTitle) document.title = t(`pages.${title}`);

    return () => {
      document.title = t(`pages.${oldTitle}`);
    };
  }, [title, currentLang]);
};
