import React from 'react';
import PropTypes from 'prop-types';
import styles from './Form.module.scss';

export const FormFooter = ({ children }) => (
  <div className={styles.footer}>{children}</div>
);

FormFooter.propTypes = {
  children: PropTypes.node,
};

FormFooter.defaultProps = {
  children: null,
};
