import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Heading, DescriptionText } from 'src/layouts/LoginLayout';
import { ButtonLink } from 'src/uikit/Button';
import { FormFooter } from 'src/uikit/AuthForm';

const SuccessMsg = ({ email }) => {
  const { t } = useTranslation('messages');

  return (
    <>
      <Heading heading={t('forgotPasswordSuccess.title')} variant="secondary" />
      <DescriptionText>
        {t('forgotPasswordSuccess.intro', { email })}
      </DescriptionText>
      <FormFooter>
        <ButtonLink
          to="/login"
          variant="primary"
          label={t('login.loginBtnLabel')}
        />
      </FormFooter>
    </>
  );
};

export default SuccessMsg;

SuccessMsg.propTypes = {
  email: PropTypes.string,
};

SuccessMsg.defaultProps = {
  email: '',
};
