import IndexPage from 'src/pages/IndexPage';
import LoginPage from 'src/pages/LoginPage';
import SignupPage from 'src/pages/SignupPage';
import ForgotPasswordPage from 'src/pages/ForgotPasswordPage';
import PasswordResetPage from 'src/pages/PasswordResetPage';
import AboutUsPage from 'src/pages/AboutUsPage';
import PrivacyPolicyPage from 'src/pages/PrivacyPolicyPage';
import DescriptionPage from 'src/pages/DescriptionPage';
import DiaryPage from 'src/pages/DiaryPage';
import ReportPage from 'src/pages/ReportPage';
import ReportPreviewPage from 'src/pages/ReportPreviewPage';
import ImprintPage from 'src/pages/ImprintPage';
import ProfilePage from 'src/pages/ProfilePage';
import HowToUsePage from 'src/pages/HowToUsePage';
import NotFoundPage from 'src/pages/NotFoundPage';

const routes = [
  {
    path: '/',
    exact: true,
    title: 'index',
    component: IndexPage,
    isAuth: true,
  },
  {
    path: '/login',
    title: 'login',
    component: LoginPage,
    isAuth: true,
  },
  {
    path: '/sign-up',
    title: 'signup',
    component: SignupPage,
    isAuth: true,
  },
  {
    path: '/forgot-password',
    title: 'forgotPassword',
    component: ForgotPasswordPage,
    isAuth: true,
  },
  {
    path: '/reset-password',
    title: 'passwordReset',
    component: PasswordResetPage,
    isAuth: true,
  },
  {
    path: '/about-us',
    title: 'aboutUs',
    component: AboutUsPage,
    isPrivate: false,
  },
  {
    path: '/privacy-policy',
    title: 'privacyPolicy',
    component: PrivacyPolicyPage,
    isPrivate: false,
  },
  {
    path: '/how-to-use',
    title: 'howToUse',
    component: HowToUsePage,
    isPrivate: false,
  },
  {
    path: '/imprint',
    title: 'imprint',
    component: ImprintPage,
    isPrivate: false,
  },
  {
    path: '/what-is-a-gastric-diary',
    title: 'whatIsAStomachDiary',
    component: DescriptionPage,
    isPrivate: false,
  },
  {
    path: '/diary',
    title: 'diary',
    component: DiaryPage,
    isPrivate: true,
  },
  {
    path: '/reports/:id',
    title: 'report',
    component: ReportPage,
    isPrivate: true,
  },
  {
    path: '/reports/:id/preview',
    title: 'report',
    component: ReportPreviewPage,
  },
  {
    path: '/profile',
    title: 'profile',
    component: ProfilePage,
    isPrivate: true,
  },
  {
    path: '*',
    title: 'pageNotFound',
    component: NotFoundPage,
  },
];

export default routes;
