export const symptoms = [
  {
    id: 1,
    title: 'feeding_and_watering_behaviours',
    symptoms: [
      'loss_of_appetite',
      'idle_chewing_and_teeth_gnashing',
      'tail_swishing_whilst_eating',
      'increased_or_decreased_drinking',
    ],
  },
  {
    id: 2,
    title: 'unusual_behaviour',
    symptoms: [
      'difficulty_lying_down',
      'wide_placement_of_front_and_hind_legs',
      'defensive_behaviour_when_being_saddled',
      'avoidance_of_other_horses_or_increased_aggression',
      'cribbing',
      'flehmen_response',
      'increased_yawning',
      'licking_objects',
      'weaving',
      'burping',
    ],
  },
  {
    id: 3,
    title: 'general_state_of_health',
    symptoms: [
      'weakened_general_condition',
      'elevated_pulse_slight_fever',
      'reoccuring_colic_at_first_relatively_mild_for_no_apparent_cause',
      'increased_salivation',
      'flatual_water',
    ],
  },
  {
    id: 4,
    title: 'appearance',
    symptoms: ['weight_loss', 'dull_coat'],
  },
  {
    id: 5,
    title: 'decline in performance',
    symptoms: [
      'drop_in_performance',
      'reduced_performance_and_rideability',
      'hesitating_on_downhill_inclines',
    ],
  },
];
