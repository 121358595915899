import React from 'react';
import PropTypes from 'prop-types';
import styles from './LoginLayout.module.scss';

export const Promo = ({ children }) => (
  <div className={styles.promoBlock}>{children}</div>
);

Promo.propTypes = {
  children: PropTypes.node,
};

Promo.defaultProps = {
  children: null,
};
