import {
  UI_SET_LANG,
  UI_SHOW_MODAL,
  UI_CLOSE_MODAL,
  UI_SET_MODE,
  UI_SET_IS_SYNCING,
  UI_SHOW_SUCCESS_TOAST,
  UI_CLOSE_SUCCESS_TOAST,
} from 'src/store/actions/actionTypes';

const initialState = {
  lang: 'en',
  modal: {},
  isSyncing: false,
  mode: 'online',
  successToast: false,
  successToastText: '',
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case UI_SET_LANG:
      return {
        ...state,
        lang: action.payload,
      };

    case UI_SHOW_MODAL:
      return {
        ...state,
        modal: action.payload,
      };

    case UI_CLOSE_MODAL:
      return {
        ...state,
        modal: {},
      };

    case UI_SET_MODE:
      return {
        ...state,
        mode: action.payload,
      };

    case UI_SET_IS_SYNCING:
      return {
        ...state,
        isSyncing: action.payload,
      };

    case UI_SHOW_SUCCESS_TOAST:
      return {
        ...state,
        successToast: true,
        successToastText: action.payload,
      };

    case UI_CLOSE_SUCCESS_TOAST:
      return {
        ...state,
        successToast: false,
        successToastText: '',
      };

    default:
      return state;
  }
};

export default reducer;
