import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { useWindowSize } from 'src/hooks/useWindowSize';
import styles from './SymptomsReportList.module.scss';

const COL_WIDTH = 62;

const TableWrapper = ({ children }) => {
  const containerRef = useRef();
  const wrapperRef = useRef();
  const scrollRef = useRef();
  const prevRef = useRef();
  const nextRef = useRef();
  const [width] = useWindowSize([0]);

  const handleScroll = (dir) =>
    (scrollRef.current.scrollLeft += dir * COL_WIDTH);

  const setNavState = () => {
    if (!scrollRef.current || !wrapperRef.current) return;

    const currentScroll = scrollRef.current.scrollLeft;

    prevRef.current.disabled = currentScroll <= 0;
    nextRef.current.disabled =
      scrollRef.current.scrollLeft + scrollRef.current.offsetWidth >=
      scrollRef.current.scrollWidth;
  };

  useEffect(() => {
    setNavState();

    if (!width) return;

    if (width <= 768) {
      wrapperRef.current.style.width = `100%`;
    } else {
      const containerWidth = containerRef.current.offsetWidth;

      const offset = width > 768 ? 238 : 156;

      const colCount = Math.floor((containerWidth - offset) / COL_WIDTH);

      const wrapperWidth = offset + colCount * COL_WIDTH;

      wrapperRef.current.style.width = `${wrapperWidth}px`;
    }

    if (width > 768 && (scrollRef.current.scrollLeft / COL_WIDTH) % 1 !== 0) {
      scrollRef.current.scrollLeft =
        Math.floor(scrollRef.current.scrollLeft / COL_WIDTH) * COL_WIDTH;
    }
  }, [width]);

  return width ? (
    <div className={styles.listContainer} ref={containerRef}>
      <div className={styles.listWrapper} ref={wrapperRef}>
        <button
          ref={prevRef}
          type="button"
          className={cx(styles.listNavArrow, styles.prev)}
          onClick={() => handleScroll(-1)}
        >
          Left
        </button>
        <button
          ref={nextRef}
          type="button"
          className={cx(styles.listNavArrow, styles.next)}
          onClick={() => handleScroll(1)}
        >
          Right
        </button>

        <div
          className={styles.tableScrollWrapper}
          ref={scrollRef}
          onScroll={setNavState}
        >
          {children}
        </div>
      </div>
    </div>
  ) : null;
};

export default TableWrapper;

TableWrapper.propTypes = {
  children: PropTypes.node,
};

TableWrapper.defaultProps = {
  children: null,
};
