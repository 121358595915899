import axios from 'axios';
import store from 'src/store/store';

const api = axios.create({
  baseURL: process.env.REACT_APP_API_BASE,
  withCredentials: true,
  headers: {
    post: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  },
});

api.interceptors.response.use(
  (response) => response,
  async (err) => {
    const errorResponse = err.response;
    const errorStatus = errorResponse.status;

    if (errorStatus === 401) {
      store.dispatch({
        type: 'AUTH_LOGOUT_USER',
      });
    }

    if (errorStatus === 419) {
      await axios.get('auth/csrf-cookie');

      return axios(err.response.config);
    }

    throw err;
  },
);

export default api;
