import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import styles from './Modal.module.scss';

const Modal = ({ onClose, size, children }) => (
  <div className={styles.wrapper} role="dialog">
    <div
      className={styles.overlay}
      role="button"
      tabIndex={0}
      onClick={onClose}
    />
    <div className={styles.inner}>
      <div className={cx(styles.content, styles[size])}>{children}</div>
    </div>
  </div>
);

export default Modal;

Modal.propTypes = {
  onClose: PropTypes.func,
  size: PropTypes.oneOf(['', 'sm']),
  children: PropTypes.node,
};

Modal.defaultProps = {
  onClose: null,
  size: '',
  children: null,
};
