export const UI_SET_LANG = 'UI_SET_LANG';
export const UI_SHOW_MODAL = 'UI_SHOW_MODAL';
export const UI_CLOSE_MODAL = 'UI_CLOSE_MODAL';
export const UI_SET_MODE = 'UI_SET_MODE';
export const UI_SET_IS_SYNCING = 'UI_SET_IS_SYNCING';
export const UI_SHOW_SUCCESS_TOAST = 'UI_SHOW_SUCCESS_TOAST';
export const UI_CLOSE_SUCCESS_TOAST = 'UI_CLOSE_SUCCESS_TOAST';

export const AUTH_SET_INITIALIZED = 'AUTH_SET_INITIALIZED';
export const AUTH_SET_USER = 'AUTH_SET_USER';
export const AUTH_LOGIN_USER = 'AUTH_LOGIN_USER';
export const AUTH_REGISTER_USER = 'AUTH_REGISTER_USER';
export const AUTH_LOGOUT_USER = 'AUTH_LOGOUT_USER';
export const AUTH_SET_ERRORS = 'AUTH_SET_ERRORS';
export const AUTH_RESET_ERRORS = 'AUTH_RESET_ERRORS';

export const USERS_UPDATE = 'USERS_UPDATE';

export const HORSES_GET_ALL = 'HORSES_GET_ALL';
export const HORSES_CREATE = 'HORSES_CREATE';
export const HORSES_UPDATE = 'HORSES_UPDATE';
export const HORSES_DELETE = 'HORSES_DELETE';
export const HORSES_SET_ERRORS = 'HORSES_SET_ERRORS';
export const HORSES_RESET_ERRORS = 'HORSES_RESET_ERRORS';
export const HORSES_RESET_ALL = 'HORSES_RESET_ALL';

export const RECORDS_GET_ONE = 'RECORDS_GET_ONE';
export const RECORDS_GET_LAST = 'RECORDS_GET_LAST';
export const RECORDS_CREATE = 'RECORDS_CREATE';
export const RECORDS_UPDATE = 'RECORDS_UPDATE';
export const RECORDS_SET_ERRORS = 'RECORDS_SET_ERRORS';
export const RECORDS_RESET = 'RECORDS_RESET';

export const REPORTS_GET_ONE = 'REPORTS_GET_ONE';
export const REPORTS_CREATE = 'REPORTS_CREATE';
export const REPORTS_SET_ERRORS = 'REPORTS_SET_ERRORS';
export const REPORTS_RESET_ALL = 'REPORTS_RESET_ALL';
