import React from 'react';
import PropTypes from 'prop-types';
import styles from './QuoteBlock.module.scss';
import icon from './icon.svg';

const QuoteBlock = ({ quote, cite }) => (
  <div className={styles.wrapper}>
    <img src={icon} alt="icon" className={styles.icon} />
    <blockquote cite={cite} className={styles.blockquote}>
      {quote}
    </blockquote>
  </div>
);

export default QuoteBlock;

QuoteBlock.propTypes = {
  quote: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  cite: PropTypes.string,
};

QuoteBlock.defaultProps = {
  quote: '',
  cite: '',
};
