import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { uiCloseModal, uiShowSuccessToast } from 'src/store/actions/uiActions';
import uuid from 'react-uuid';
import moment from 'moment';
import {
  createRecord,
  getLastRecord,
  resetRecords,
} from 'src/store/actions/recordActions';
import useForm from 'src/hooks/useForm';
import useReportScroll from 'src/hooks/useReportScroll';
import {
  NewReportModalWrapper,
  NewReportModalHeader,
  NewReportModalFooter,
  NewReportModalBtnDown,
} from 'src/uikit/NewReportModal';
import ReportFormList from 'src/uikit/ReportFormList';
import { Button } from 'src/uikit/Button';
import DatePicker from 'src/uikit/DatePicker';
import { symptoms } from 'src/constants/symptoms';

const RecordNewModal = ({ modal = {} }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation('messages');
  const { reportId, horseName, date } = modal;
  const { values, setValues, handleStateChange } = useForm({
    id: uuid(),
    report_id: reportId,
    date,
    symptoms: [],
  });
  const { scrolled, wrapperRef, scrollProgress } = useReportScroll();
  const [hasCopy, setHasCopy] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleClose = () => dispatch(uiCloseModal());

  const onSuccess = () => {
    handleClose();
    dispatch(uiShowSuccessToast());
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    setIsSubmitting(true);

    const formData = JSON.stringify(values);

    dispatch(createRecord(formData, onSuccess));
  };

  const handleCopyPrev = () => {
    dispatch(
      getLastRecord(reportId, (res) => {
        if (!res.symptoms) return;

        setValues((initValues) => ({
          ...initValues,
          symptoms: res.symptoms,
        }));

        setHasCopy(uuid());
      }),
    );
  };

  const handleScrollDown = () => {
    const windowHeight = window.innerHeight;
    wrapperRef.current.scrollBy(0, windowHeight / 2);
  };

  useEffect(
    () => () => {
      dispatch(resetRecords());
    },
    [],
  );

  return (
    <NewReportModalWrapper
      title={t('reportNewModal.title')}
      onClose={handleClose}
      forwardRef={wrapperRef}
      onScroll={scrollProgress}
    >
      <form onSubmit={handleSubmit} noValidate>
        <NewReportModalHeader
          horseName={horseName}
          date={
            <DatePicker
              defaultDate={moment(date).format('YYYY-MM-DD')}
              disabled
            />
          }
          canCopy
          onCopy={handleCopyPrev}
          copyLabel={t('actions.copyFromPrevLabel')}
          scrollProgress={scrolled}
        />

        <ReportFormList
          key={hasCopy}
          defaultValue={values.symptoms}
          symptoms={symptoms}
          onChange={(val) => handleStateChange('symptoms', val)}
        />

        <NewReportModalFooter>
          {scrolled < 90 && (
            <NewReportModalBtnDown onClick={handleScrollDown} />
          )}

          <Button
            type="submit"
            variant="primary"
            label={t('actions.done')}
            fullwidth
            isLoading={isSubmitting}
          />
        </NewReportModalFooter>
      </form>
    </NewReportModalWrapper>
  );
};

export default RecordNewModal;

RecordNewModal.propTypes = {
  modal: PropTypes.shape({
    type: PropTypes.string,
    reportId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    horseName: PropTypes.string,
    date: PropTypes.string,
  }),
};

RecordNewModal.defaultProps = {
  modal: {},
};
