import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import styles from './OfflineMsg.module.scss';

const OfflineMsg = () => {
  const { t } = useTranslation('messages');
  const { mode } = useSelector((state) => state.uiReducer);

  return mode === 'offline' ? (
    <div className={styles.wrapper}>{t('offlineMsg')}</div>
  ) : null;
};

export default OfflineMsg;
