import api from 'src/helpers/apiConfig';

const urlBase = 'users';

const updateLocale = (data) => api.post(`${urlBase}/locale`, data);
const update = (data) => api.post(`${urlBase}/?_method=PUT`, data);

const UserService = {
  updateLocale,
  update,
};

export default UserService;
