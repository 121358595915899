import { openDB } from 'idb';

const DB_NAME = 'StomachDiaryDB';

export const initDb = async () => {
  const db = await openDB(DB_NAME, 1, {
    upgrade(dbObj) {
      const userStore = dbObj.createObjectStore('users', {
        keyPath: 'id',
        autoIncrement: true,
      });
      userStore.createIndex('id', 'id');

      const horseStore = dbObj.createObjectStore('horses', {
        keyPath: 'id',
        autoIncrement: true,
      });
      horseStore.createIndex('id', 'id');

      const reportStore = dbObj.createObjectStore('reports', {
        keyPath: 'id',
        autoIncrement: true,
      });
      reportStore.createIndex('id', 'id');
    },
  });

  db.close();
};

export const clearTable = async (key) => {
  const db = await openDB(DB_NAME, 1);

  const rows = await db.getAllFromIndex(key, 'id');

  const tx = db.transaction(key, 'readwrite');

  await Promise.all([...rows.map((row) => tx.store.delete(row.id)), tx.done]);

  db.close();

  return true;
};

export const deleteRow = async (key, id) => {
  const db = await openDB(DB_NAME, 1);

  const tx = db.transaction(key, 'readwrite');

  tx.store.delete(id);

  db.close();

  return true;
};

export const storeRecords = async (key, data) => {
  const db = await openDB(DB_NAME, 1);

  const tx = db.transaction(key, 'readwrite');

  await Promise.all([
    ...data.map((item) => tx.store.put({ ...item })),
    tx.done,
  ]);

  db.close();

  return true;
};

export const storeRecord = async (key, data) => {
  const db = await openDB(DB_NAME, 1);

  const tx = db.transaction(key, 'readwrite');

  tx.store.put({ ...data });

  db.close();

  return true;
};

export const getRecords = async (key) => {
  const db = await openDB(DB_NAME, 1);

  const records = await db.getAllFromIndex(key, 'id');

  db.close();

  return records;
};

export const getRecord = async (key, id) => {
  const db = await openDB(DB_NAME, 1);

  const tx = db.transaction(key, 'readwrite');

  const record = await tx.store.get(id);

  db.close();

  return record;
};
