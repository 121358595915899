import React from 'react';
import PropTypes from 'prop-types';
import styles from './ErrorNotice.module.scss';

const ErrorNotice = ({ children }) => (
  <div className={styles.errorNotice}>{children}</div>
);

export default ErrorNotice;

ErrorNotice.propTypes = {
  children: PropTypes.node,
};

ErrorNotice.defaultProps = {
  children: null,
};
