import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { uiCloseSuccessToast } from 'src/store/actions/uiActions';
import styles from './SuccessToast.module.scss';

const SuccessToast = () => {
  const { t } = useTranslation('messages');
  const dispatch = useDispatch();
  const { successToast, successToastText } = useSelector(
    (state) => state.uiReducer,
  );

  useEffect(() => {
    if (!successToast) return;

    setTimeout(() => {
      dispatch(uiCloseSuccessToast());
    }, 1500);
  }, [successToast]);

  return successToast ? (
    <div className={styles.wrapper}>{successToastText || t('successMsg')}</div>
  ) : null;
};

export default SuccessToast;
