import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';
import cx from 'classnames';
import { setLang } from 'src/store/actions/uiActions';
import styles from './LanguageSelect.module.scss';

const languages = ['en', 'de'];

const LanguageSelect = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation('messages');
  const currentLang = i18n.language;
  const [isOpen, setIsOpen] = useState(false);
  const toggleRef = useRef();

  const handleSelect = (lang) => dispatch(setLang(lang));

  const handleBodyClick = useCallback((e) => {
    if (e.target === toggleRef || toggleRef.current.contains(e.target)) return;

    setIsOpen(false);
  }, []);

  useEffect(() => {
    if (isOpen) {
      document.body.addEventListener('click', handleBodyClick, true);
    } else {
      document.body.removeEventListener('click', handleBodyClick, true);
    }

    return () => {
      document.body.removeEventListener('click', handleBodyClick, true);
    };
  }, [isOpen]);

  return (
    <div className={cx(styles.wrapper, isOpen && styles.isOpen)}>
      <button
        ref={toggleRef}
        type="button"
        className={styles.toggle}
        onClick={() => setIsOpen(!isOpen)}
      >
        {t(`language.${currentLang}`)}
      </button>

      <div className={styles.menu}>
        <ul className={styles.list}>
          {languages.map((lang) => (
            <li key={lang} className={styles.listItem}>
              <button
                type="button"
                className={cx(
                  styles.listLink,
                  lang === currentLang && styles.isSelected,
                )}
                onClick={() => handleSelect(lang)}
              >
                {t(`language.${lang}`)}
              </button>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default LanguageSelect;
