import React from 'react';
import PropTypes from 'prop-types';
import styles from './RichMediaBlock.module.scss';

const RichMediaBlock = ({ imgSrc, imgAlt, mediaContent, children }) => (
  <div className={styles.wrapper}>
    <div className={styles.mediaCol}>
      {imgSrc && <img src={imgSrc} alt={imgAlt} className={styles.img} />}
      {mediaContent && (
        <div className={styles.mediaContent}>{mediaContent}</div>
      )}
    </div>
    <div className={styles.mainCol}>{children}</div>
  </div>
);

export default RichMediaBlock;

RichMediaBlock.propTypes = {
  imgSrc: PropTypes.string,
  imgAlt: PropTypes.string,
  mediaContent: PropTypes.node,
  children: PropTypes.node,
};

RichMediaBlock.defaultProps = {
  imgSrc: '',
  imgAlt: '',
  mediaContent: null,
  children: null,
};
