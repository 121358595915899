import {
  HORSES_GET_ALL,
  HORSES_CREATE,
  HORSES_UPDATE,
  HORSES_DELETE,
  HORSES_SET_ERRORS,
  HORSES_RESET_ERRORS,
  HORSES_RESET_ALL,
} from 'src/store/actions/actionTypes';

const initialState = {
  horses: {},
  errors: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case HORSES_GET_ALL:
      return {
        ...state,
        horses: action.payload,
        errors: null,
      };

    case HORSES_SET_ERRORS:
      return {
        ...state,
        errors: {
          status: action.payload?.status || '',
          message: action.payload?.data?.message,
          errors: action.payload?.data?.errors,
        },
      };

    case HORSES_CREATE:
      return {
        ...state,
        horses: {
          ...state.subjects,
          data: [action.payload, ...state.horses.data],
          total: (state.horses.total || 0) + 1,
        },
        errors: null,
      };

    case HORSES_UPDATE:
      return {
        ...state,
        horses: {
          ...state.horses,
          data: [...state.horses.data].map((item) =>
            item.id === action.payload.id ? { ...action.payload } : item,
          ),
        },
        errors: null,
      };

    case HORSES_DELETE:
      return {
        ...state,
        horses: {
          ...state.horses,
          data: [...state.horses.data].filter(
            (item) => item.id !== action.payload,
          ),
        },
        errors: null,
      };

    case HORSES_RESET_ERRORS:
      return {
        ...state,
        errors: null,
      };

    case HORSES_RESET_ALL:
      return {
        ...initialState,
      };

    default:
      return state;
  }
};

export default reducer;
