import React from 'react';
import PropTypes from 'prop-types';
import styles from './AuthForm.module.scss';

export const FormNote = ({ children }) => (
  <div className={styles.note}>{children}</div>
);

FormNote.propTypes = {
  children: PropTypes.node,
};

FormNote.defaultProps = {
  children: null,
};
