import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import styles from './LoginLayout.module.scss';

export const Heading = ({ heading, variant }) => (
  <h1 className={cx(styles.heading, styles[variant])}>{heading}</h1>
);

Heading.propTypes = {
  heading: PropTypes.string,
  variant: PropTypes.oneOf(['primary', 'secondary', 'small']),
};

Heading.defaultProps = {
  heading: '',
  variant: 'primary',
};
