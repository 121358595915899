import React from 'react';
import PropTypes from 'prop-types';
import styles from './NewReportModal.module.scss';

export const NewReportModalWrapper = ({
  title,
  onClose,
  children,
  forwardRef,
  onScroll,
}) => (
  <div
    className={styles.wrapper}
    role="dialog"
    ref={forwardRef}
    onScroll={onScroll}
  >
    <div
      className={styles.overlay}
      role="button"
      tabIndex={0}
      onClick={onClose}
    />
    <div className={styles.inner}>
      <div className={styles.content}>
        <div className="container">
          <div className={styles.contentInner}>
            <h3 className={styles.title}>{title}</h3>

            <button type="button" className={styles.closeBtn} onClick={onClose}>
              Close
            </button>

            {children}
          </div>
        </div>
      </div>
    </div>
  </div>
);

NewReportModalWrapper.propTypes = {
  title: PropTypes.string,
  onClose: PropTypes.func,
  children: PropTypes.node,
  forwardRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  ]),
  onScroll: PropTypes.func,
};

NewReportModalWrapper.defaultProps = {
  title: '',
  onClose: null,
  children: null,
  forwardRef: null,
  onScroll: () => {},
};
