import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { NavLink } from 'react-router-dom';
import { ButtonIconLink } from 'src/uikit/Button';
import logo from 'src/assets/img/logo.svg';
import styles from './ReportLayout.module.scss';

export const ReportHeader = ({ backLink, title, subtitle }) => (
  <div className={styles.header}>
    {backLink && (
      <div className={styles.headerBackContainer}>
        <ButtonIconLink
          icon="icon-arrow-left"
          variant="secondary"
          to={backLink}
          title="back"
        />
      </div>
    )}

    <div className={styles.headerMain}>
      <i className={cx(styles.headerIcon, 'icon-horse')} />
      {title && <h1 className={styles.headerHeading}>{title}</h1>}
      {subtitle && <div className={styles.headerSubtitle}>{subtitle}</div>}
    </div>

    <NavLink to="/diary">
      <img src={logo} alt="Equine 74 logo" className={styles.headerLogo} />
    </NavLink>
  </div>
);

ReportHeader.propTypes = {
  backLink: PropTypes.string,
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

ReportHeader.defaultProps = {
  backLink: '',
  title: '',
  subtitle: '',
};
