import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { uiCloseModal, uiShowSuccessToast } from 'src/store/actions/uiActions';
import {
  createHorse,
  updateHorse,
  resetHorsesErrors,
} from 'src/store/actions/horseActions';
import useForm from 'src/hooks/useForm';
import Modal from 'src/uikit/Modal';
import { FormItem, FormFooter } from 'src/uikit/Form';
import { Button } from 'src/uikit/Button';
import TextInput from 'src/uikit/TextInput';
import OfflineNotice from 'src/uikit/OfflineNotice';
import styles from './HorseEditModal.module.scss';
import { initialFieldValues } from './constants';

const HorseEditModal = ({ modal }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation('messages');
  const { horse } = modal;
  const { mode } = useSelector((state) => state.uiReducer);
  const { errors } = useSelector((state) => state.horseReducer);
  const { values, handleStateChange, getErrorMsg } = useForm(
    horse || initialFieldValues,
  );
  const [inputErrors, setInputErrors] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleClose = () => dispatch(uiCloseModal());

  const onSuccess = () => {
    handleClose();
    dispatch(uiShowSuccessToast());
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    setIsSubmitting(true);
    setInputErrors(null);

    const formData = JSON.stringify(values);

    if (horse && horse.id) {
      dispatch(updateHorse(horse.id, formData, onSuccess));
    } else {
      dispatch(createHorse(formData, onSuccess));
    }
  };

  useEffect(() => {
    setIsSubmitting(false);
    setInputErrors(errors?.errors);
  }, [errors]);

  useEffect(
    () => () => {
      dispatch(resetHorsesErrors());
    },
    [],
  );

  return (
    <Modal onClose={handleClose} size="sm">
      <div className={styles.wrapper}>
        <div className={styles.icon} />

        <h3 className={styles.heading}>
          {horse ? t('horseEditModal.editTitle') : t('horseEditModal.title')}
        </h3>

        {mode === 'offline' && (
          <div className="mb-16">
            <OfflineNotice />
          </div>
        )}

        <form onSubmit={handleSubmit} noValidate>
          <FormItem>
            <TextInput
              type="text"
              name="name"
              id="name"
              defaultValue={horse?.name}
              onChange={(e) => handleStateChange('name', e.target.value)}
              label={t('horseEditModal.nameLabel')}
              error={getErrorMsg(inputErrors, 'name')}
              disabled={mode === 'offline'}
            />
          </FormItem>

          <FormItem>
            <TextInput
              placeholder="DD/MM/YYYY"
              type="date"
              name="birthdate"
              id="birthdate"
              defaultValue={horse?.birthdate}
              onChange={(e) => handleStateChange('birthdate', e.target.value)}
              label={t('horseEditModal.birthdateLabel')}
              error={getErrorMsg(inputErrors, 'birthdate')}
              disabled={mode === 'offline'}
            />
          </FormItem>

          <FormFooter>
            <Button
              type="submit"
              variant="primary"
              label={t('actions.saveLabel')}
              isLoading={isSubmitting}
              disabled={mode === 'offline'}
            />
          </FormFooter>
        </form>
      </div>
    </Modal>
  );
};

export default HorseEditModal;

HorseEditModal.propTypes = {
  modal: PropTypes.shape({
    type: PropTypes.string,
    horse: PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      name: PropTypes.string,
      age: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      birthdate: PropTypes.string,
    }),
  }),
};

HorseEditModal.defaultProps = {
  modal: {},
};
