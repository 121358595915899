import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import SwitchToggle from 'src/uikit/SwitchToggle';
import styles from './ReportFormList.module.scss';

const ReportFormList = ({ symptoms, defaultValue, onChange }) => {
  const { t } = useTranslation('messages');
  const [selected, setSelected] = useState(defaultValue);

  const handleSymptomChange = (e) => {
    const { name, checked } = e.target;

    let nextSelected = [...selected];

    if (checked && !nextSelected.includes(name)) nextSelected.push(name);

    if (!checked && nextSelected.includes(name))
      nextSelected = nextSelected.filter((item) => item !== name);

    setSelected(nextSelected);
    onChange(nextSelected);
  };

  return (
    <div className={styles.listWrapper}>
      <div className={styles.list}>
        {symptoms.map((symptomGroup) => (
          <div
            key={symptomGroup.id}
            id={symptomGroup.title}
            className={styles.section}
          >
            <h4 className={styles.sectionTitle}>
              {t(`symptoms.${symptomGroup.title}`)}
            </h4>
            <div className={styles.sectionInner}>
              {symptomGroup.symptoms.map((symptom) => (
                <label key={symptom} className={styles.item}>
                  <span className={styles.itemLabel}>
                    {t(`symptoms.${symptom}`)}
                  </span>
                  <span className={styles.itemInputWrapper}>
                    <SwitchToggle
                      name={symptom}
                      defaultChecked={selected.includes(symptom)}
                      onChange={handleSymptomChange}
                    />
                  </span>
                </label>
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ReportFormList;

ReportFormList.propTypes = {
  symptoms: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      title: PropTypes.string,
      symptoms: PropTypes.arrayOf(PropTypes.string),
    }),
  ),
  defaultValue: PropTypes.arrayOf(PropTypes.string),
  onChange: PropTypes.func,
};

ReportFormList.defaultProps = {
  symptoms: [],
  defaultValue: [],
  onChange: null,
};
