import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Page from 'src/routing/Page';
import routes from 'src/pages/routes';

const Pages = () => (
  <Router>
    <Routes>
      {routes.map((route) => (
        <Route
          key={route.path}
          exact={route.exact}
          path={route.path}
          element={
            <Page
              title={route.title}
              isPrivate={route.isPrivate}
              isAuth={route.isAuth}
              component={route.component}
            />
          }
        />
      ))}
    </Routes>
  </Router>
);

export default Pages;
