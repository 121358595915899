import api from 'src/helpers/apiConfig';

const urlBase = 'auth';

const getUser = () => api.get(`${urlBase}/`);
const loginUser = (userData) => api.post(`${urlBase}/login`, userData);
const registerUser = (data) => api.post(`${urlBase}/register`, data);
const logoutUser = () => api.post(`${urlBase}/logout`);
const forgotPassword = (data) => api.post(`${urlBase}/forgot-password`, data);
const resetPassword = (data) => api.post(`${urlBase}/reset-password`, data);
const updatePassword = (data) =>
  api.post(`${urlBase}/password?_method=PUT`, data);

const AuthService = {
  getUser,
  loginUser,
  registerUser,
  logoutUser,
  forgotPassword,
  resetPassword,
  updatePassword,
};

export default AuthService;
