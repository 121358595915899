import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';
import { updateUser, resetUserErrors } from 'src/store/actions/userActions';
import { uiShowModal, uiShowSuccessToast } from 'src/store/actions/uiActions';
import useForm from 'src/hooks/useForm';
import { LoginLayout, Heading, IntroBlock } from 'src/layouts/LoginLayout';
import { Button } from 'src/uikit/Button';
import TextInput from 'src/uikit/TextInput';
import Select from 'src/uikit/Select';
import { FormWrapper, FormItem, FormFooter } from 'src/uikit/AuthForm';
import Checkbox from 'src/uikit/Checkbox';
import OfflineNotice from 'src/uikit/OfflineNotice';
import { countriesEn, countriesDe } from 'src/constants/countries';
import { initialFieldValues } from './constants';

const ProfilePage = () => {
  const { t } = useTranslation('messages');
  const currentLang = i18n.language;
  const dispatch = useDispatch();
  const { authUser, errors } = useSelector((state) => state.authReducer);
  const { mode } = useSelector((state) => state.uiReducer);
  const { values, setValues, handleStateChange, getErrorMsg } = useForm(
    initialFieldValues,
  );
  const [inputErrors, setInputErrors] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const countries = currentLang === 'en' ? countriesEn : countriesDe;

  const onSuccess = () => {
    setIsSubmitting(false);
    dispatch(uiShowSuccessToast());
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    setIsSubmitting(true);
    setInputErrors(null);

    const formData = JSON.stringify(values);

    dispatch(updateUser(formData, onSuccess));
  };

  const handlePasswordModal = () =>
    dispatch(
      uiShowModal({
        type: 'PASSWORD_UPADTE_MODAL',
      }),
    );

  useEffect(() => {
    if (!authUser || !authUser.id) return;

    setValues(authUser);
  }, [authUser]);

  useEffect(() => {
    setIsSubmitting(false);
    setInputErrors(errors?.errors);
  }, [errors]);

  useEffect(
    () => () => {
      dispatch(resetUserErrors());
    },
    [],
  );

  return (
    <LoginLayout>
      <div className="container">
        <IntroBlock>
          <Heading heading={t('profilePage.title')} variant="secondary" />

          <FormWrapper>
            {mode === 'offline' && (
              <div className="mb-16">
                <OfflineNotice />
              </div>
            )}

            <form onSubmit={handleSubmit} noValidate>
              <FormItem>
                <TextInput
                  type="text"
                  name="firstname"
                  id="firstname"
                  defaultValue={authUser?.firstname}
                  onChange={(e) =>
                    handleStateChange('firstname', e.target.value)
                  }
                  label={t('login.nameLabel')}
                  error={getErrorMsg(inputErrors, 'firstname')}
                  disabled={mode === 'offline'}
                />
              </FormItem>

              <FormItem>
                <TextInput
                  type="text"
                  name="lastname"
                  id="lastname"
                  defaultValue={authUser?.lastname}
                  onChange={(e) =>
                    handleStateChange('lastname', e.target.value)
                  }
                  label={t('login.lastnameLabel')}
                  error={getErrorMsg(inputErrors, 'lastname')}
                  disabled={mode === 'offline'}
                />
              </FormItem>

              <FormItem>
                <Select
                  name="country"
                  id="country"
                  defaultValue={authUser?.country}
                  onChange={(e) => handleStateChange('country', e.target.value)}
                  label={t('login.countryLabel')}
                  error={getErrorMsg(inputErrors, 'country')}
                  tooltip={t('login.countryTooltip')}
                  disabled={mode === 'offline'}
                >
                  <option value="" />

                  {countries.map((country) => (
                    <option key={country.id} value={country.id}>
                      {country.label}
                    </option>
                  ))}
                </Select>
              </FormItem>

              <FormItem>
                <TextInput
                  type="email"
                  name="email"
                  id="email"
                  defaultValue={authUser?.email}
                  onChange={(e) => handleStateChange('email', e.target.value)}
                  label={t('login.emailLabel')}
                  error={getErrorMsg(inputErrors, 'email')}
                  comment={
                    <Button
                      type="button"
                      variant="link"
                      label={t('login.changePasswordLabel')}
                      onClick={handlePasswordModal}
                    />
                  }
                  disabled={mode === 'offline'}
                />
              </FormItem>

              <FormItem>
                <Checkbox
                  label={t('login.marketingLabel')}
                  defaultChecked={!!authUser?.marketing}
                  onChange={(e) =>
                    handleStateChange('marketing', e.target.checked)
                  }
                  disabled={mode === 'offline'}
                />
              </FormItem>

              <FormFooter>
                <Button
                  type="submit"
                  variant="primary"
                  label={t('actions.saveLabel')}
                  isLoading={isSubmitting}
                  disabled={mode === 'offline'}
                />
              </FormFooter>
            </form>
          </FormWrapper>
        </IntroBlock>
      </div>
    </LoginLayout>
  );
};

export default ProfilePage;
