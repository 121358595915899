import React from 'react';
import PropTypes from 'prop-types';
import styles from './LoginLayout.module.scss';

export const IntroBlock = ({ children }) => (
  <div className={styles.introBlock}>{children}</div>
);

IntroBlock.propTypes = {
  children: PropTypes.node,
};

IntroBlock.defaultProps = {
  children: null,
};
