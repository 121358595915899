import React from 'react';
import PropTypes from 'prop-types';
import styles from './Checkbox.module.scss';

const Checkbox = ({
  id,
  name,
  label,
  value,
  checked,
  defaultChecked,
  onChange,
  error,
}) => (
  <div className={styles.wrapper}>
    <label className={styles.inner}>
      <input
        type="checkbox"
        id={id}
        name={name}
        value={value}
        checked={checked}
        defaultChecked={defaultChecked}
        onChange={onChange}
        className={styles.input}
      />
      <span className={styles.checkmark} />
      {label && <span className={styles.label}>{label}</span>}
    </label>
    {error && <span className={styles.error}>{error}</span>}
  </div>
);

export default Checkbox;

Checkbox.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.string,
  checked: PropTypes.bool,
  defaultChecked: PropTypes.bool,
  onChange: PropTypes.func,
  error: PropTypes.string,
};

Checkbox.defaultProps = {
  id: '',
  name: '',
  label: '',
  value: '',
  checked: undefined,
  defaultChecked: undefined,
  onChange: null,
  error: '',
};
