import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { uiCloseModal, uiShowSuccessToast } from 'src/store/actions/uiActions';
import { deleteHorse } from 'src/store/actions/horseActions';
import Modal from 'src/uikit/Modal';
import { FormFooter } from 'src/uikit/Form';
import { Button } from 'src/uikit/Button';
import OfflineNotice from 'src/uikit/OfflineNotice';
import styles from './HorseDeleteModal.module.scss';

const HorseDeleteModal = ({ modal }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation('messages');
  const { horse } = modal;
  const { mode } = useSelector((state) => state.uiReducer);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleClose = () => dispatch(uiCloseModal());

  const onSuccess = () => {
    handleClose();
    dispatch(uiShowSuccessToast());
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    setIsSubmitting(true);

    dispatch(deleteHorse(horse.id, onSuccess));
  };

  return (
    <Modal onClose={handleClose} size="sm">
      <div className={styles.wrapper}>
        <div className={styles.icon} />

        <h3 className={styles.heading}>{t('horseDeleteModal.title')}</h3>

        {mode === 'offline' && (
          <div className="mb-16">
            <OfflineNotice />
          </div>
        )}

        <FormFooter>
          <Button
            type="button"
            variant="primary"
            label={t('actions.deleteLabel')}
            onClick={handleSubmit}
            isLoading={isSubmitting}
            disabled={mode === 'offline'}
          />

          <Button
            type="button"
            variant="secondary"
            label={t('actions.cancelLabel')}
            onClick={handleClose}
            disabled={mode === 'offline'}
          />
        </FormFooter>
      </div>
    </Modal>
  );
};

export default HorseDeleteModal;

HorseDeleteModal.propTypes = {
  modal: PropTypes.shape({
    type: PropTypes.string,
    horse: PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      name: PropTypes.string,
      age: PropTypes.number,
    }),
  }),
};

HorseDeleteModal.defaultProps = {
  modal: {},
};
