import React, { useEffect } from 'react';
import { authGetUser, logoutUser } from 'src/store/actions/authActions';
import { Provider, useSelector, useDispatch } from 'react-redux';
import { uiSetMode } from 'src/store/actions/uiActions';
import { initDb } from 'src/utils/dbUtils';
import LocalProvider from 'src/LocalProvider';
import store from 'src/store/store';
import Pages from 'src/pages';
import { Cookies } from 'react-cookie';
import i18n from 'i18next';
import { makeSync } from 'src/store/actions/syncActions';
import 'src/assets/css/styles.scss';

const cookies = new Cookies();
const storedLang = cookies.get('locale');
if (storedLang) i18n.changeLanguage(storedLang);

initDb();

const App = () => (
  <LocalProvider>
    <Provider store={store}>
      <AppAuth />
      <AppSync />
      <Pages />
    </Provider>
  </LocalProvider>
);

export default App;

const AppAuth = () => {
  const dispatch = useDispatch();
  const { mode } = useSelector((state) => state.uiReducer);
  const { authUser } = useSelector((state) => state.authReducer);

  useEffect(() => {
    if (!authUser || !authUser.id) return;

    i18n.changeLanguage(authUser.locale);
  }, [authUser]);

  useEffect(() => {
    if (localStorage.getItem('logout') && navigator.onLine)
      dispatch(logoutUser());

    dispatch(authGetUser());
  }, []);

  useEffect(() => {
    dispatch(authGetUser());
  }, [mode]);

  return null;
};

const AppSync = () => {
  const dispatch = useDispatch();
  const { mode } = useSelector((state) => state.uiReducer);
  const { isAuthenticated } = useSelector((state) => state.authReducer);

  const setOnline = () => dispatch(uiSetMode('online'));

  const setOffline = () => dispatch(uiSetMode('offline'));

  useEffect(() => {
    dispatch(uiSetMode(navigator.onLine ? 'online' : 'offline'));

    window.addEventListener('offline', setOffline);
    window.addEventListener('online', setOnline);

    return () => {
      window.removeEventListener('offline', setOffline);
      window.removeEventListener('online', setOnline);
    };
  }, []);

  useEffect(() => {
    if (!isAuthenticated || mode === 'offline') return;

    dispatch(makeSync());
  }, [isAuthenticated, mode]);
};
