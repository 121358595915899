import i18n from 'i18next';
import UserService from 'src/services/userService';
import { Cookies } from 'react-cookie';
import {
  UI_SET_LANG,
  UI_SHOW_MODAL,
  UI_CLOSE_MODAL,
  UI_SET_MODE,
  UI_SHOW_SUCCESS_TOAST,
  UI_CLOSE_SUCCESS_TOAST,
} from 'src/store/actions/actionTypes';

export const setLang = (nextLang) => (dispatch) => {
  const cookies = new Cookies();
  cookies.set('locale', nextLang, { path: '/', maxAge: 604800 });

  i18n.changeLanguage(nextLang);

  UserService.updateLocale({ locale: nextLang })
    .then(() =>
      dispatch({
        type: UI_SET_LANG,
        payload: nextLang,
      }),
    )
    .catch(() => false);
};

export const uiShowModal = (modal) => (dispatch) => {
  dispatch({
    type: UI_SHOW_MODAL,
    payload: modal,
  });
};

export const uiCloseModal = (type) => (dispatch) => {
  dispatch({
    type: UI_CLOSE_MODAL,
    payload: type,
  });
};

export const uiSetMode = (mode) => (dispatch) =>
  dispatch({ type: UI_SET_MODE, payload: mode });

export const uiShowSuccessToast = (text) => (dispatch) =>
  dispatch({ type: UI_SHOW_SUCCESS_TOAST, payload: text });

export const uiCloseSuccessToast = () => (dispatch) =>
  dispatch({ type: UI_CLOSE_SUCCESS_TOAST });
