import React from 'react';
import { useTranslation } from 'react-i18next';
import styles from './OfflineNotice.module.scss';

const OfflineNotice = () => {
  const { t } = useTranslation('messages');

  return <div className={styles.offlineNotice}>{t('offlineNotice')}</div>;
};

export default OfflineNotice;
