import React from 'react';
import PropTypes from 'prop-types';
import styles from './ReportLayout.module.scss';

export const ReportFooter = ({ children }) => (
  <div className={styles.footer}>
    <div className={styles.footerInner}>{children}</div>
  </div>
);

ReportFooter.propTypes = {
  children: PropTypes.node,
};

ReportFooter.defaultProps = {
  children: null,
};
