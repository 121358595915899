import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import styles from './TextInput.module.scss';

const TextInput = ({
  id,
  name,
  type,
  label,
  defaultValue,
  value,
  onChange,
  error,
  comment,
  tooltip,
  inputRef,
  ...rest
}) => (
  <div className={cx(styles.wrapper, error && styles.hasError)}>
    {label && (
      <label htmlFor={id} className={styles.label}>
        {label}
        {tooltip && (
          <span className={styles.tooltip}>
            <i className="icon-info" />
            <span className={styles.tooltipContainer}>{tooltip}</span>
          </span>
        )}
      </label>
    )}
    <input
      type={type}
      id={id}
      name={name}
      defaultValue={defaultValue}
      value={value}
      onChange={onChange}
      className={styles.input}
      ref={inputRef}
      {...rest}
    />
    {error && <span className={styles.error}>{error}</span>}
    {comment && <span className={styles.comment}>{comment}</span>}
  </div>
);

export default TextInput;

TextInput.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  type: PropTypes.string,
  label: PropTypes.string,
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func,
  error: PropTypes.string,
  comment: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  tooltip: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  inputRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  ]),
};

TextInput.defaultProps = {
  id: '',
  name: '',
  type: 'text',
  label: '',
  defaultValue: undefined,
  value: undefined,
  onChange: null,
  error: '',
  comment: '',
  tooltip: '',
  inputRef: null,
};
