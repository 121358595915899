import React from 'react';
import { useTranslation } from 'react-i18next';
import InnerPageLayout from 'src/layouts/InnerPageLayout';
import styles from './NotFoundPage.module.scss';

const NotFoundPage = () => {
  const { t } = useTranslation('messages');

  return (
    <InnerPageLayout>
      <div className="container">
        <div className={styles.pretitle}>404</div>
        <h1 className="main-heading">{t('notFoundPage.title')}</h1>
      </div>
    </InnerPageLayout>
  );
};

export default NotFoundPage;
