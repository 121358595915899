import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  authForgotPassword,
  resetAuthErrors,
} from 'src/store/actions/authActions';
import useForm from 'src/hooks/useForm';
import {
  LoginLayout,
  Heading,
  DescriptionText,
  IntroBlock,
} from 'src/layouts/LoginLayout';
import { Button, ButtonLink } from 'src/uikit/Button';
import TextInput from 'src/uikit/TextInput';
import { FormWrapper, FormItem, FormFooter } from 'src/uikit/AuthForm';
import OfflineNotice from 'src/uikit/OfflineNotice';
import SuccessMsg from './SuccessMsg';
import { initialFieldValues } from './constants';

const ForgotPasswordPage = () => {
  const { t } = useTranslation('messages');
  const dispatch = useDispatch();
  const { errors } = useSelector((state) => state.authReducer);
  const { mode } = useSelector((state) => state.uiReducer);
  const { values, handleStateChange, getErrorMsg } = useForm(
    initialFieldValues,
  );
  const [inputErrors, setInputErrors] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);

  const handleSuccess = () => {
    setIsSubmitting(false);
    setIsSuccess(true);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    setIsSubmitting(true);
    setInputErrors(null);

    const formData = JSON.stringify(values);

    dispatch(authForgotPassword(formData, handleSuccess));
  };

  useEffect(() => {
    setIsSubmitting(false);
    setInputErrors(errors?.errors);
  }, [errors]);

  useEffect(
    () => () => {
      dispatch(resetAuthErrors());
    },
    [],
  );

  return (
    <LoginLayout>
      <div className="container">
        <IntroBlock>
          {isSuccess ? (
            <SuccessMsg email={values.email} />
          ) : (
            <>
              <Heading
                heading={t('forgotPasswordPage.title')}
                variant="secondary"
              />
              <DescriptionText>{t('forgotPasswordPage.intro')}</DescriptionText>
              <FormWrapper>
                {mode === 'offline' && (
                  <div className="mb-16">
                    <OfflineNotice />
                  </div>
                )}

                <form onSubmit={handleSubmit} noValidate>
                  <FormItem>
                    <TextInput
                      type="email"
                      name="email"
                      id="email"
                      defaultValue=""
                      onChange={(e) =>
                        handleStateChange('email', e.target.value)
                      }
                      label={t('login.emailLabel')}
                      error={getErrorMsg(inputErrors, 'email')}
                      comment={
                        <ButtonLink
                          variant="link"
                          to="/login"
                          label={t('login.loginBtnLabel')}
                        />
                      }
                      disabled={mode === 'offline'}
                    />
                  </FormItem>

                  <FormFooter>
                    <Button
                      type="submit"
                      variant="primary"
                      label={t('login.sendPasswordResetBtnLabel')}
                      fullwidth
                      isLoading={isSubmitting}
                      disabled={mode === 'offline'}
                    />
                  </FormFooter>
                </form>
              </FormWrapper>
            </>
          )}
        </IntroBlock>
      </div>
    </LoginLayout>
  );
};

export default ForgotPasswordPage;
