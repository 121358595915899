import React, { useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import { getModal } from 'src/modals';

const Modal = ({ component: Component, ...rest }) =>
  Component ? <Component {...rest} /> : null;

Modal.propTypes = {
  component: PropTypes.elementType,
};

Modal.defaultProps = {
  component: null,
};

const ModalWrapper = ({ modal, component }) =>
  useMemo(() => <Modal component={component} modal={modal} />, [modal]);

const Modals = () => {
  const { modal } = useSelector((state) => state.uiReducer);

  return (
    <TransitionGroup>
      <CSSTransitionItem key={modal.type} inProp={!!modal.type} modal={modal} />
    </TransitionGroup>
  );
};

export default Modals;

const CSSTransitionItem = ({ modal, inProp, ...rest }) => {
  const nodeRef = useRef(null);

  return (
    <CSSTransition
      in={inProp}
      timeout={200}
      nodeRef={nodeRef}
      classNames="modal"
      {...rest}
    >
      <div className="modal-animation-wrapper" ref={nodeRef}>
        <ModalWrapper component={getModal(modal.type)} modal={modal} />
      </div>
    </CSSTransition>
  );
};

CSSTransitionItem.propTypes = {
  modal: PropTypes.shape({
    type: PropTypes.string,
    data: PropTypes.any,
  }),
  inProp: PropTypes.bool,
};

CSSTransitionItem.defaultProps = {
  modal: null,
  inProp: false,
};
