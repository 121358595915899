import api from 'src/helpers/apiConfig';

const urlBase = 'records';

const getOne = (id) => api.get(`${urlBase}/${id}`);
const getLast = (reportId) => api.get(`${urlBase}/report/${reportId}/last`);
const create = (data) => api.post(`${urlBase}`, data);
const update = (id, data) => api.post(`${urlBase}/${id}?_method=PUT`, data);

const RecordService = {
  getOne,
  getLast,
  create,
  update,
};

export default RecordService;
