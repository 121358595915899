import React, { useState, useCallback, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { DayPicker } from 'react-day-picker';
import moment from 'moment';
import cx from 'classnames';
import styles from './DatePicker.module.scss';

const DatePicker = ({ defaultDate, maxDate, onChange, disabled }) => {
  const elRef = useRef();
  const [isOpen, setIsOpen] = useState(false);
  const [selected, setSelected] = useState(moment(defaultDate).toDate());

  const handleChange = (date) => {
    setSelected(date);
    onChange(moment(date).format('YYYY-MM-DD'));
  };

  const handleBodyClick = useCallback((e) => {
    if (e.target === elRef || elRef.current.contains(e.target)) return;

    setIsOpen(false);
  }, []);

  useEffect(() => {
    if (isOpen) {
      document.body.addEventListener('click', handleBodyClick, true);
    } else {
      document.body.removeEventListener('click', handleBodyClick, true);
    }

    return () => {
      document.body.removeEventListener('click', handleBodyClick, true);
    };
  }, [isOpen]);

  return (
    <div className={cx(styles.wrapper, isOpen && styles.isOpen)} ref={elRef}>
      <button
        type="button"
        className={styles.toggle}
        onClick={() => setIsOpen(!isOpen)}
        disabled={disabled}
      >
        {moment(selected).format('DD.MM.YYYY')}
      </button>

      <div className={styles.menu}>
        <div className={styles.pickerWrapper}>
          <DayPicker
            weekStartsOn={1}
            mode="single"
            selected={selected}
            onSelect={handleChange}
            disabled={[
              {
                after: moment(maxDate).toDate(),
              },
            ]}
          />
        </div>
      </div>
    </div>
  );
};

export default DatePicker;

DatePicker.propTypes = {
  defaultDate: PropTypes.string,
  maxDate: PropTypes.string,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
};

DatePicker.defaultProps = {
  defaultDate: undefined,
  maxDate: undefined,
  disabled: false,
  onChange: null,
};
