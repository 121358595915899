import React from 'react';
import PropTypes from 'prop-types';
import styles from './Slider.module.scss';

export const Slide = ({ imgSrc, imgAlt, title, dscr }) => (
  <div className={styles.slide}>
    <div className={styles.slideInner}>
      {imgSrc && <img src={imgSrc} alt={imgAlt} className={styles.slideImg} />}
      {title && <h3 className={styles.slideTitle}>{title}</h3>}
      {dscr && <p className={styles.slideDscr}>{dscr}</p>}
    </div>
  </div>
);

Slide.propTypes = {
  imgSrc: PropTypes.string,
  imgAlt: PropTypes.string,
  title: PropTypes.string,
  dscr: PropTypes.string,
};

Slide.defaultProps = {
  imgSrc: '',
  imgAlt: '',
  title: '',
  dscr: '',
};
