import { RECORDS_GET_ONE, RECORDS_RESET } from 'src/store/actions/actionTypes';

const initialState = {
  record: {},
  errors: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case RECORDS_GET_ONE:
      return {
        ...state,
        record: action.payload,
        errors: null,
      };

    case RECORDS_RESET:
      return {
        ...initialState,
      };

    default:
      return state;
  }
};

export default reducer;
