import React from 'react';
import { useTranslation } from 'react-i18next';
import InnerPageLayout from 'src/layouts/InnerPageLayout';
import TextBlock from 'src/uikit/TextBlock';

const ImprintPage = () => {
  const { t } = useTranslation('messages');

  return (
    <InnerPageLayout>
      <div className="container">
        <h1 className="main-heading">{t('imprintPage.title')}</h1>

        <TextBlock>
          <div
            dangerouslySetInnerHTML={{
              __html: t('imprintPage.text', {
                interpolation: { escapeValue: false },
              }),
            }}
          />
        </TextBlock>
      </div>
    </InnerPageLayout>
  );
};

export default ImprintPage;
