import HorseEditModal from 'src/modals/HorseEditModal';
import HorseDeleteModal from 'src/modals/HorseDeleteModal';
import ReportNewModal from 'src/modals/ReportNewModal';
import RecordEditModal from 'src/modals/RecordEditModal';
import RecordNewModal from 'src/modals/RecordNewModal';
import ExportModal from 'src/modals/ExportModal';
import PasswordUpdateModal from 'src/modals/PasswordUpdateModal';

export const getModal = (type) => {
  switch (type) {
    case 'HORSE_EDIT_MODAL':
      return HorseEditModal;

    case 'HORSE_DELETE_MODAL':
      return HorseDeleteModal;

    case 'REPORT_NEW_MODAL':
      return ReportNewModal;

    case 'RECORD_EDIT_MODAL':
      return RecordEditModal;

    case 'RECORD_NEW_MODAL':
      return RecordNewModal;

    case 'EXPORT_MODAL':
      return ExportModal;

    case 'PASSWORD_UPADTE_MODAL':
      return PasswordUpdateModal;

    default:
      return null;
  }
};
