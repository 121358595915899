import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { uiCloseModal, uiShowSuccessToast } from 'src/store/actions/uiActions';
import {
  updateUserPassword,
  resetAuthErrors,
} from 'src/store/actions/authActions';
import useForm from 'src/hooks/useForm';
import Modal from 'src/uikit/Modal';
import { FormItem, FormFooter } from 'src/uikit/Form';
import { Button } from 'src/uikit/Button';
import TextInput from 'src/uikit/TextInput';
import OfflineNotice from 'src/uikit/OfflineNotice';
import styles from './PasswordUpdateModal.module.scss';
import { initialFieldValues } from './constants';

const PasswordUpdateModal = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation('messages');
  const { errors } = useSelector((state) => state.authReducer);
  const { mode } = useSelector((state) => state.uiReducer);
  const { values, handleStateChange, getErrorMsg } = useForm(
    initialFieldValues,
  );
  const [inputErrors, setInputErrors] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleClose = () => dispatch(uiCloseModal());

  const onSuccess = () => {
    handleClose();
    dispatch(uiShowSuccessToast());
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    setIsSubmitting(true);
    setInputErrors(null);

    const formData = JSON.stringify(values);

    dispatch(updateUserPassword(formData, onSuccess));
  };

  useEffect(() => {
    setIsSubmitting(false);
    setInputErrors(errors?.errors);
  }, [errors]);

  useEffect(
    () => () => {
      dispatch(resetAuthErrors());
    },
    [],
  );

  return (
    <Modal onClose={handleClose} size="sm">
      <div className={styles.wrapper}>
        <h3 className={styles.heading}>{t('passwordUpdateModal.title')}</h3>

        <form onSubmit={handleSubmit} noValidate>
          {mode === 'offline' && (
            <div className="mb-16">
              <OfflineNotice />
            </div>
          )}

          <FormItem>
            <TextInput
              type="password"
              name="old_password"
              id="old_password"
              defaultValue=""
              onChange={(e) =>
                handleStateChange('old_password', e.target.value)
              }
              label={t('login.oldPasswordLabel')}
              error={getErrorMsg(inputErrors, 'old_password')}
              disabled={mode === 'offline'}
            />
          </FormItem>

          <FormItem>
            <TextInput
              type="password"
              name="password"
              id="password"
              defaultValue=""
              onChange={(e) => handleStateChange('password', e.target.value)}
              label={t('login.newPasswordLabel')}
              tooltip={
                <span
                  dangerouslySetInnerHTML={{
                    __html: t('login.passwordStrength', {
                      interpolation: { escapeValue: false },
                    }),
                  }}
                />
              }
              error={getErrorMsg(inputErrors, 'password')}
              disabled={mode === 'offline'}
            />
          </FormItem>

          <FormFooter>
            <Button
              type="submit"
              variant="primary"
              label={t('actions.saveLabel')}
              isLoading={isSubmitting}
              disabled={mode === 'offline'}
            />
          </FormFooter>
        </form>
      </div>
    </Modal>
  );
};

export default PasswordUpdateModal;
