import ReportService from 'src/services/reportService';
import { getRecord, storeRecord } from 'src/utils/dbUtils';
import {
  REPORTS_GET_ONE,
  REPORTS_CREATE,
  REPORTS_SET_ERRORS,
  REPORTS_RESET_ALL,
} from './actionTypes';

const storeOneData = async (data) => {
  await storeRecord('reports', data);

  const horse = await getRecord('horses', data.horse.id);

  horse.reports = [...horse.reports, { ...data }];

  await storeRecord('horses', horse);

  return true;
};

export const getOneReport = (id) => (dispatch) => {
  if (!navigator.onLine) {
    return getRecord('reports', id)
      .then((res) =>
        dispatch({
          type: REPORTS_GET_ONE,
          payload: res,
        }),
      )
      .catch((err) =>
        dispatch({
          type: REPORTS_SET_ERRORS,
          payload: err,
        }),
      );
  }

  return ReportService.getOne(id)
    .then((res) => {
      storeRecord('reports', res.data);

      return dispatch({
        type: REPORTS_GET_ONE,
        payload: res.data,
      });
    })
    .catch((err) =>
      dispatch({
        type: REPORTS_SET_ERRORS,
        payload: err.response,
      }),
    );
};

export const createReport = (data, onSuccess) => (dispatch) => {
  ReportService.create(data)
    .then((res) => {
      if (onSuccess) onSuccess(res.data);

      storeOneData(res.data);

      return dispatch({
        type: REPORTS_CREATE,
        payload: res.data,
      });
    })
    .catch((err) =>
      dispatch({
        type: REPORTS_SET_ERRORS,
        payload: err.response,
      }),
    );
};

export const resetReports = () => (dispatch) =>
  dispatch({
    type: REPORTS_RESET_ALL,
  });
