import React from 'react';
import PropTypes from 'prop-types';
import styles from './AuthForm.module.scss';

export const FormWrapper = ({ children }) => (
  <div className={styles.wrapper}>{children}</div>
);

FormWrapper.propTypes = {
  children: PropTypes.node,
};

FormWrapper.defaultProps = {
  children: null,
};
