import slide1 from './slide-1.jpg';
import slide2 from './slide-2.jpg';
import slide3 from './slide-3.jpg';

export const slides = [
  {
    id: 1,
    img: slide1,
    title: 'indexPage.slider.slide_1.title',
    dscr: 'indexPage.slider.slide_1.dscr',
  },
  {
    id: 2,
    img: slide2,
    title: 'indexPage.slider.slide_2.title',
    dscr: 'indexPage.slider.slide_2.dscr',
  },
  {
    id: 3,
    img: slide3,
    title: 'indexPage.slider.slide_3.title',
    dscr: 'indexPage.slider.slide_3.dscr',
  },
];
