import React, { useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link, NavLink } from 'react-router-dom';
import cx from 'classnames';
import { logoutUser } from 'src/store/actions/authActions';
import styles from './Nav.module.scss';

const Nav = () => {
  const { t } = useTranslation('messages');
  const menuRef = useRef();
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);
  const { isAuthenticated } = useSelector((state) => state.authReducer);

  const handleClose = (e) => {
    if (menuRef.current && !menuRef.current.contains(e.target)) {
      setIsOpen(false);
    }
  };

  const handleLogout = () => dispatch(logoutUser());

  return (
    <>
      <button
        type="button"
        className={styles.toggle}
        onClick={() => setIsOpen(true)}
      >
        Menu
      </button>

      <div
        className={cx(styles.wrapper, isOpen && styles.isOpen)}
        onMouseDown={handleClose}
        role="button"
        aria-label="close"
        tabIndex="0"
      >
        <div className={styles.backdrop} />
        <div ref={menuRef} className={styles.menu}>
          <button
            type="button"
            onClick={() => setIsOpen(false)}
            className={styles.closeBtn}
          >
            Close
          </button>

          <div className={styles.menuInner}>
            <ul className={styles.primaryList}>
              <li className={styles.primaryItem}>
                <NavLink to="/diary" className={styles.primaryLink}>
                  {t('nav.diary')}
                </NavLink>
              </li>
              <li className={styles.primaryItem}>
                <NavLink
                  to="/what-is-a-gastric-diary"
                  className={styles.primaryLink}
                >
                  {t('nav.what_is_a_gastric_diary')}
                </NavLink>
              </li>
              <li className={styles.primaryItem}>
                <NavLink to="/how-to-use" className={styles.primaryLink}>
                  {t('nav.howToUse')}
                </NavLink>
              </li>
              <li className={styles.primaryItem}>
                <NavLink to="/about-us" className={styles.primaryLink}>
                  {t('nav.about_us')}
                </NavLink>
              </li>
              <li className={styles.primaryItem}>
                <Link
                  to="https://shop.equine74.com"
                  className={styles.primaryLink}
                  target="_blank"
                >
                  {t('nav.shop')} <i className="icon-share" />
                </Link>
              </li>
            </ul>

            <ul className={styles.secondaryList}>
              {isAuthenticated ? (
                <>
                  <li className={styles.secondaryItem}>
                    <NavLink to="/profile" className={styles.secondaryLink}>
                      {t('nav.profile')}
                    </NavLink>
                  </li>
                  <li className={styles.secondaryItem}>
                    <button
                      type="button"
                      className={styles.secondaryLink}
                      onClick={handleLogout}
                    >
                      {t('nav.signout')}
                    </button>
                  </li>
                </>
              ) : (
                <li className={styles.secondaryItem}>
                  <NavLink to="/login" className={styles.secondaryLink}>
                    {t('nav.signin')}
                  </NavLink>
                </li>
              )}

              <li className={styles.secondaryItem}>
                <NavLink to="/privacy-policy" className={styles.secondaryLink}>
                  {t('nav.privacy_policy')}
                </NavLink>
              </li>

              <li className={styles.secondaryItem}>
                <NavLink to="/imprint" className={styles.secondaryLink}>
                  {t('nav.imprint')}
                </NavLink>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default Nav;
