import React from 'react';
import { useTranslation } from 'react-i18next';
import InnerPageLayout from 'src/layouts/InnerPageLayout';
import RichMediaBlock from 'src/uikit/RichMediaBlock';
import { ButtonLink } from 'src/uikit/Button';
import QuoteBlock from 'src/uikit/QuoteBlock';

const DescriptionPage = () => {
  const { t } = useTranslation('messages');

  return (
    <InnerPageLayout>
      <div className="container">
        <h1 className="main-heading">{t('descriptionPage.title')}</h1>

        <RichMediaBlock
          imgSrc="img/description.png"
          imgAlt={t('aboutUsPage.title')}
          mediaContent={
            <>
              <QuoteBlock quote={t('descriptionPage.quote')} />
              <div className="mt-16">{t('descriptionPage.quoteSrcLabel')}</div>
            </>
          }
        >
          <div
            dangerouslySetInnerHTML={{
              __html: t('descriptionPage.text', {
                interpolation: { escapeValue: false },
              }),
            }}
          />

          <div className="mt-24">
            <ButtonLink
              to="/diary"
              variant="link"
              size="lg"
              label={t('descriptionPage.ctaBtnLabel')}
            />
          </div>
        </RichMediaBlock>
      </div>
    </InnerPageLayout>
  );
};

export default DescriptionPage;
