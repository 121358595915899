import HorseService from 'src/services/horseService';
import ReportService from 'src/services/reportService';
import { getRecords, clearTable, storeRecords } from 'src/utils/dbUtils';
import { UI_SET_IS_SYNCING } from './actionTypes';

const getAllOfflineChanges = async () => {
  const reports = await getRecords('reports');

  const allRecords = reports.reduce(
    (acc, item) => [...acc, ...(item.records || [])],
    [],
  );

  const changes = allRecords.filter((r) => r.needSync);

  return changes || [];
};

const uploadChanges = async (changes) => {
  const res = await ReportService.sync(JSON.stringify({ records: changes }));

  return res;
};

const downloadHorses = async () => {
  const resp = await HorseService.getAll()
    .then((res) => storeRecords('horses', res.data?.data))
    .catch((err) => err.response);

  return resp;
};

const downloadReports = async (query) => {
  const resp = await ReportService.getAll(query)
    .then((res) => {
      if (res.data?.data) storeRecords('reports', res.data?.data);

      if (res.data?.last_page !== res.data?.current_page)
        return downloadReports({ page: res.data.current_page + 1 });

      return true;
    })
    .catch((err) => err.response);

  return resp;
};

export const makeSync = () => async (dispatch) => {
  if (!navigator.onLine) return false;

  dispatch({ type: UI_SET_IS_SYNCING, payload: true });

  try {
    const changes = await getAllOfflineChanges();

    if (changes) await uploadChanges(changes);

    await clearTable('horses');

    await clearTable('reports');

    await downloadHorses();

    await downloadReports();
  } catch (e) {}

  return dispatch({ type: UI_SET_IS_SYNCING, payload: false });
};
