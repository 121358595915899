import React from 'react';
import { useTranslation } from 'react-i18next';
import InnerPageLayout from 'src/layouts/InnerPageLayout';
import RichMediaBlock from 'src/uikit/RichMediaBlock';
import { ButtonLink } from 'src/uikit/Button';

const AboutUsPage = () => {
  const { t } = useTranslation('messages');

  return (
    <InnerPageLayout>
      <div className="container">
        <h1 className="main-heading">{t('aboutUsPage.title')}</h1>

        <RichMediaBlock
          imgSrc="img/about-us.jpg"
          imgAlt={t('aboutUsPage.title')}
        >
          <div
            dangerouslySetInnerHTML={{
              __html: t('aboutUsPage.text', {
                interpolation: { escapeValue: false },
              }),
            }}
          />

          <div className="mt-32">
            <ButtonLink
              to="https://www.equine74.com/"
              target="_blank"
              label={t('aboutUsPage.ctaBtnLabel')}
              variant="primary"
              wide
            />
          </div>

          <div className="mt-32">
            <p>{t('aboutUsPage.subtext')}</p>
          </div>
        </RichMediaBlock>
      </div>
    </InnerPageLayout>
  );
};

export default AboutUsPage;
