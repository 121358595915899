import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import { uiCloseModal, uiShowSuccessToast } from 'src/store/actions/uiActions';
import {
  getOneRecord,
  updateRecord,
  resetRecords,
} from 'src/store/actions/recordActions';
import useForm from 'src/hooks/useForm';
import useReportScroll from 'src/hooks/useReportScroll';
import {
  NewReportModalWrapper,
  NewReportModalHeader,
  NewReportModalFooter,
  NewReportModalBtnDown,
} from 'src/uikit/NewReportModal';
import ReportFormList from 'src/uikit/ReportFormList';
import { Button } from 'src/uikit/Button';
import DatePicker from 'src/uikit/DatePicker';
import { symptoms } from 'src/constants/symptoms';

const RecordEditModal = ({ modal }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation('messages');
  const { recordId, horseName } = modal;
  const { record } = useSelector((state) => state.recordReducer);
  const { values, setValues, handleStateChange } = useForm({
    report_id: '',
    date: moment().format('YYYY-MM-DD'),
    symptoms: [],
  });
  const { scrolled, wrapperRef, scrollProgress } = useReportScroll();
  const [isLoading, setIsLoading] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleClose = () => dispatch(uiCloseModal());

  const onSuccess = () => {
    handleClose();
    dispatch(uiShowSuccessToast());
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    setIsSubmitting(true);

    const formData = JSON.stringify(values);

    dispatch(updateRecord(record.id, formData, onSuccess));
  };

  const handleScrollDown = () => {
    const windowHeight = window.innerHeight;
    wrapperRef.current.scrollBy(0, windowHeight / 2);
  };

  useEffect(() => {
    if (!recordId) return;

    dispatch(getOneRecord(recordId));
  }, [recordId]);

  useEffect(() => {
    if (!record || !record.id) return;

    setIsLoading(false);

    setValues({ ...record });
  }, [record]);

  useEffect(
    () => () => {
      dispatch(resetRecords());
    },
    [],
  );

  return (
    <NewReportModalWrapper
      title={t('reportNewModal.title')}
      onClose={handleClose}
      forwardRef={wrapperRef}
      onScroll={scrollProgress}
    >
      <form onSubmit={handleSubmit} noValidate key={isLoading}>
        <NewReportModalHeader
          horseName={horseName}
          date={
            <DatePicker
              defaultDate={moment(record.date).format('YYYY-MM-DD')}
              disabled
            />
          }
          scrollProgress={scrolled}
        />

        <ReportFormList
          symptoms={symptoms}
          defaultValue={record.symptoms}
          onChange={(val) => handleStateChange('symptoms', val)}
        />

        <NewReportModalFooter>
          {scrolled < 90 && (
            <NewReportModalBtnDown onClick={handleScrollDown} />
          )}

          <Button
            type="submit"
            variant="primary"
            label={t('actions.done')}
            fullwidth
            isLoading={isSubmitting}
          />
        </NewReportModalFooter>
      </form>
    </NewReportModalWrapper>
  );
};

export default RecordEditModal;

RecordEditModal.propTypes = {
  modal: PropTypes.shape({
    type: PropTypes.string,
    recordId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    horseName: PropTypes.string,
  }),
};

RecordEditModal.defaultProps = {
  modal: {},
};
