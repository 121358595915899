import React from 'react';
import PropTypes from 'prop-types';
import styles from './SwitchToggle.module.scss';

const SwitchToggle = ({
  name,
  value,
  checked,
  defaultChecked,
  onChange,
  disabled,
}) => (
  <div className={styles.wrapper}>
    <label className={styles.label}>
      <input
        type="checkbox"
        name={name}
        value={value}
        checked={checked}
        defaultChecked={defaultChecked}
        onChange={onChange}
        className={styles.input}
        disabled={disabled}
      />
      <span className={styles.toggle} />
    </label>
  </div>
);

export default SwitchToggle;

SwitchToggle.propTypes = {
  name: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  checked: PropTypes.bool,
  defaultChecked: PropTypes.bool,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
};

SwitchToggle.defaultProps = {
  name: '',
  value: '',
  checked: undefined,
  defaultChecked: undefined,
  onChange: null,
  disabled: false,
};
