import api from 'src/helpers/apiConfig';

const urlBase = 'reports';

const getAll = (query) => api.get(`${urlBase}/`, { params: { ...query } });
const getOne = (id) => api.get(`${urlBase}/${id}`);
const create = (data) => api.post(`${urlBase}/`, data);
const sync = (data) => api.post(`${urlBase}/sync`, data);

const ReportService = {
  getAll,
  getOne,
  create,
  sync,
};

export default ReportService;
