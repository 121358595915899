import React from 'react';
import PropTypes from 'prop-types';
import PrivateRoute from 'src/routing/PrivateRoute';
import AuthRoute from 'src/routing/AuthRoute';
import { usePageTitle } from 'src/hooks/usePageTitle';
import { useScrollTop } from 'src/hooks/useScrollTop';

const Page = ({ title, component: Component, isPrivate, isAuth, ...rest }) => {
  usePageTitle(title);
  useScrollTop(title);

  if (isAuth) return <AuthRoute component={Component} {...rest} />;

  if (isPrivate) return <PrivateRoute component={Component} {...rest} />;

  return <Component {...rest} />;
};

export default Page;

Page.propTypes = {
  title: PropTypes.string,
  component: PropTypes.elementType,
  isPrivate: PropTypes.bool,
  isAuth: PropTypes.bool,
};

Page.defaultProps = {
  title: '',
  component: null,
  isPrivate: false,
  isAuth: false,
};
