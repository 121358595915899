import {
  REPORTS_GET_ONE,
  REPORTS_SET_ERRORS,
  REPORTS_RESET_ALL,
  RECORDS_CREATE,
  RECORDS_UPDATE,
} from 'src/store/actions/actionTypes';

const initialState = {
  report: {},
  errors: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case REPORTS_GET_ONE:
      return {
        ...state,
        report: action.payload,
        errors: null,
      };

    case REPORTS_SET_ERRORS:
      return {
        ...state,
        errors: {
          status: action.payload?.status || '',
          message: action.payload?.data?.message,
          errors: action.payload?.data?.errors,
        },
      };

    case RECORDS_CREATE:
      return {
        ...state,
        report: {
          ...state.report,
          records: [...state.report.records, { ...action.payload }],
        },
      };

    case RECORDS_UPDATE:
      return {
        ...state,
        report: {
          ...state.report,
          records: [...state.report.records].map((item) =>
            item.id === action.payload.id ? { ...action.payload } : item,
          ),
        },
      };

    case REPORTS_RESET_ALL:
      return {
        ...initialState,
      };

    default:
      return state;
  }
};

export default reducer;
