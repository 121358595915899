import RecordService from 'src/services/recordService';
import { getRecords, getRecord, storeRecord } from 'src/utils/dbUtils';
import {
  RECORDS_GET_ONE,
  RECORDS_GET_LAST,
  RECORDS_UPDATE,
  RECORDS_CREATE,
  RECORDS_SET_ERRORS,
  RECORDS_RESET,
} from './actionTypes';

const storeOneData = async (data, needSync = false) => {
  const report = await getRecord('reports', data.report_id);

  report.records = [...report.records, { ...data, needSync }];

  await storeRecord('reports', report);

  const symptoms = report.records?.reduce((acc, item) => {
    item.symptoms.map((s) => (acc.includes(s) ? s : acc.push(s)));
    return acc;
  }, []);

  const horse = await getRecord('horses', report.horse.id);

  const horseReport = horse.reports.find((r) => r.id === report.id);

  horseReport.symptoms_count = symptoms.length;

  await storeRecord('horses', horse);

  return true;
};

const updateOneData = async (id, data, needSync = false) => {
  const report = await getRecord('reports', data.report_id);

  report.records = [...report.records].map((item) =>
    item.id === data.id ? { ...data, needSync } : item,
  );

  await storeRecord('reports', report);

  const symptoms = report.records?.reduce((acc, item) => {
    item.symptoms.map((s) => (acc.includes(s) ? s : acc.push(s)));
    return acc;
  }, []);

  const horse = await getRecord('horses', report.horse.id);

  const horseReport = horse.reports.find((r) => r.id === report.id);

  horseReport.symptoms_count = symptoms.length;

  await storeRecord('horses', horse);

  return true;
};

const getOneRecordOffline = async (id) => {
  const reports = await getRecords('reports');

  const allRecords = reports.reduce(
    (acc, item) => [...acc, ...(item.records || [])],
    [],
  );

  const record = allRecords.find((r) => r.id === id);

  return record || {};
};

export const getOneRecord = (id) => (dispatch) => {
  if (!navigator.onLine) {
    return getOneRecordOffline(id)
      .then((res) =>
        dispatch({
          type: RECORDS_GET_ONE,
          payload: res,
        }),
      )
      .catch((err) =>
        dispatch({
          type: RECORDS_SET_ERRORS,
          payload: err,
        }),
      );
  }

  return RecordService.getOne(id)
    .then((res) =>
      dispatch({
        type: RECORDS_GET_ONE,
        payload: res.data,
      }),
    )
    .catch((err) =>
      dispatch({
        type: RECORDS_SET_ERRORS,
        payload: err.response,
      }),
    );
};

export const getLastRecord = (reportId, onSuccess) => (dispatch) => {
  RecordService.getLast(reportId)
    .then((res) => {
      if (onSuccess) onSuccess(res.data);

      return dispatch({
        type: RECORDS_GET_LAST,
        payload: res.data,
      });
    })
    .catch((err) =>
      dispatch({
        type: RECORDS_SET_ERRORS,
        payload: err.response,
      }),
    );
};

export const updateRecord = (id, data, onSuccess) => (dispatch) => {
  if (!navigator.onLine) {
    const parsedData = JSON.parse(data);

    return updateOneData(id, parsedData, true)
      .then(() => {
        if (onSuccess) onSuccess(parsedData);

        return dispatch({
          type: RECORDS_UPDATE,
          payload: parsedData,
        });
      })
      .catch((err) =>
        dispatch({
          type: RECORDS_SET_ERRORS,
          payload: err,
        }),
      );
  }

  return RecordService.update(id, data)
    .then((res) => {
      if (onSuccess) onSuccess(res.data);

      updateOneData(id, res.data, false);

      return dispatch({
        type: RECORDS_UPDATE,
        payload: res.data,
      });
    })
    .catch((err) =>
      dispatch({
        type: RECORDS_SET_ERRORS,
        payload: err.response,
      }),
    );
};

export const createRecord = (data, onSuccess) => (dispatch) => {
  if (!navigator.onLine) {
    const parsedData = JSON.parse(data);

    return storeOneData(parsedData, true)
      .then(() => {
        if (onSuccess) onSuccess(parsedData);

        return dispatch({
          type: RECORDS_CREATE,
          payload: parsedData,
        });
      })
      .catch((err) =>
        dispatch({
          type: RECORDS_SET_ERRORS,
          payload: err,
        }),
      );
  }

  return RecordService.create(data)
    .then((res) => {
      if (onSuccess) onSuccess(res.data);

      storeOneData(res.data, false);

      return dispatch({
        type: RECORDS_CREATE,
        payload: res.data,
      });
    })
    .catch((err) =>
      dispatch({
        type: RECORDS_SET_ERRORS,
        payload: err.response,
      }),
    );
};

export const resetRecords = () => (dispatch) =>
  dispatch({ type: RECORDS_RESET });
