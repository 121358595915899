import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  LoginLayout,
  Logo,
  Heading,
  DescriptionText,
  IntroBlock,
  AuthBtnBlock,
} from 'src/layouts/LoginLayout';
import { ButtonLink } from 'src/uikit/Button';
import { SliderWrapper, Slide } from 'src/uikit/Slider';
import { slides } from './slides';

const IndexPage = () => {
  const { t } = useTranslation('messages');

  return (
    <LoginLayout>
      <div className="container">
        <IntroBlock>
          <Logo />
          <Heading heading={t('indexPage.title')} variant="primary" />
          <DescriptionText>{t('indexPage.dscr')}</DescriptionText>
          <AuthBtnBlock>
            <ButtonLink
              to="/sign-up"
              label={t('actions.signUp')}
              variant="primary"
            />
            <ButtonLink
              to="/login"
              label={t('actions.logIn')}
              variant="secondary"
            />
          </AuthBtnBlock>
        </IntroBlock>

        <SliderWrapper>
          {slides.map((slide) => (
            <Slide
              key={slide.id}
              imgSrc={slide.img}
              imgAlt={t(slide.title)}
              title={t(slide.title)}
              dscr={t(slide.dscr)}
            />
          ))}
        </SliderWrapper>
      </div>
    </LoginLayout>
  );
};

export default IndexPage;
