import UserService from 'src/services/userService';
import {
  USERS_UPDATE,
  AUTH_SET_ERRORS,
  AUTH_RESET_ERRORS,
} from './actionTypes';

export const updateUser = (data, onSuccess) => (dispatch) => {
  UserService.update(data)
    .then((res) => {
      if (onSuccess) onSuccess();

      return dispatch({
        type: USERS_UPDATE,
        payload: res.data,
      });
    })
    .catch((err) =>
      dispatch({
        type: AUTH_SET_ERRORS,
        payload: err.response,
      }),
    );
};

export const resetUserErrors = () => (dispatch) => {
  dispatch({
    type: AUTH_RESET_ERRORS,
  });
};
