import React from 'react';
import PropTypes from 'prop-types';
import TopBar from 'src/components/TopBar';
import Modals from 'src/components/Modals';
import PullToRefresh from 'src/components/PullToRefresh';
import CookieNotice from 'src/uikit/CookieNotice';
import OfflineMsg from 'src/uikit/OfflineMsg';
import SyncingMsg from 'src/uikit/SyncingMsg';
import SuccessToast from 'src/uikit/SuccessToast';
import bgLogo from 'src/assets/img/bgLogo.svg';
import styles from './MainLayout.module.scss';

const MainLayout = ({ children }) => (
  <div className={styles.wrapper} style={{ backgroundImage: `url(${bgLogo})` }}>
    <PullToRefresh />
    <OfflineMsg />
    <SyncingMsg />
    <SuccessToast />
    <TopBar />
    <div className={styles.inner}>{children}</div>
    <Modals />
    <CookieNotice />
  </div>
);

export default MainLayout;

MainLayout.propTypes = {
  children: PropTypes.node,
};

MainLayout.defaultProps = {
  children: null,
};
