export const countriesEn = [
  { id: 'Afghanistan', label: 'Afghanistan' },
  { id: 'Åland Islands', label: 'Åland Islands' },
  { id: 'Albania', label: 'Albania' },
  { id: 'Algeria', label: 'Algeria' },
  { id: 'American Samoa', label: 'American Samoa' },
  { id: 'Andorra', label: 'Andorra' },
  { id: 'Angola', label: 'Angola' },
  { id: 'Anguilla', label: 'Anguilla' },
  { id: 'Antarctica', label: 'Antarctica' },
  { id: 'Antigua and Barbuda', label: 'Antigua and Barbuda' },
  { id: 'Argentina', label: 'Argentina' },
  { id: 'Armenia', label: 'Armenia' },
  { id: 'Aruba', label: 'Aruba' },
  { id: 'Asia/Pacific Region', label: 'Asia/Pacific Region' },
  { id: 'Australia', label: 'Australia' },
  { id: 'Austria', label: 'Austria' },
  { id: 'Azerbaijan', label: 'Azerbaijan' },
  { id: 'Bahamas', label: 'Bahamas' },
  { id: 'Bahrain', label: 'Bahrain' },
  { id: 'Bangladesh', label: 'Bangladesh' },
  { id: 'Barbados', label: 'Barbados' },
  { id: 'Belarus', label: 'Belarus' },
  { id: 'Belgium', label: 'Belgium' },
  { id: 'Belize', label: 'Belize' },
  { id: 'Benin', label: 'Benin' },
  { id: 'Bermuda', label: 'Bermuda' },
  { id: 'Bhutan', label: 'Bhutan' },
  { id: 'Bolivia', label: 'Bolivia' },
  { id: 'Bosnia and Herzegovina', label: 'Bosnia and Herzegovina' },
  { id: 'Botswana', label: 'Botswana' },
  { id: 'Bouvet Island', label: 'Bouvet Island' },
  { id: 'Brazil', label: 'Brazil' },
  {
    id: 'British Indian Ocean Territory',
    label: 'British Indian Ocean Territory',
  },
  { id: 'British Virgin Islands', label: 'British Virgin Islands' },
  { id: 'Brunei', label: 'Brunei' },
  { id: 'Bulgaria', label: 'Bulgaria' },
  { id: 'Burkina Faso', label: 'Burkina Faso' },
  { id: 'Burundi', label: 'Burundi' },
  { id: 'Cambodia', label: 'Cambodia' },
  { id: 'Cameroon', label: 'Cameroon' },
  { id: 'Canada', label: 'Canada' },
  { id: 'Canary Islands', label: 'Canary Islands' },
  { id: 'Cape Verde', label: 'Cape Verde' },
  { id: 'Caribbean Netherlands', label: 'Caribbean Netherlands' },
  { id: 'Cayman Islands', label: 'Cayman Islands' },
  { id: 'Central African Republic', label: 'Central African Republic' },
  { id: 'Chad', label: 'Chad' },
  { id: 'Chile', label: 'Chile' },
  { id: 'China', label: 'China' },
  { id: 'Christmas Island', label: 'Christmas Island' },
  { id: 'Cocos (Keeling) Islands', label: 'Cocos (Keeling) Islands' },
  { id: 'Colombia', label: 'Colombia' },
  { id: 'Comoros', label: 'Comoros' },
  { id: 'Congo', label: 'Congo' },
  { id: 'Cook Islands', label: 'Cook Islands' },
  { id: 'Costa Rica', label: 'Costa Rica' },
  { id: "Cote d'Ivoire", label: "Cote d'Ivoire" },
  { id: 'Croatia', label: 'Croatia' },
  { id: 'Cuba', label: 'Cuba' },
  { id: 'Curaçao', label: 'Curaçao' },
  { id: 'Cyprus', label: 'Cyprus' },
  { id: 'Czech Republic', label: 'Czech Republic' },
  {
    id: 'Democratic Republic of the Congo',
    label: 'Democratic Republic of the Congo',
  },
  { id: 'Denmark', label: 'Denmark' },
  { id: 'Djibouti', label: 'Djibouti' },
  { id: 'Dominica', label: 'Dominica' },
  { id: 'Dominican Republic', label: 'Dominican Republic' },
  { id: 'East Timor', label: 'East Timor' },
  { id: 'Ecuador', label: 'Ecuador' },
  { id: 'Egypt', label: 'Egypt' },
  { id: 'El Salvador', label: 'El Salvador' },
  { id: 'Equatorial Guinea', label: 'Equatorial Guinea' },
  { id: 'Eritrea', label: 'Eritrea' },
  { id: 'Estonia', label: 'Estonia' },
  { id: 'Ethiopia', label: 'Ethiopia' },
  { id: 'Europe', label: 'Europe' },
  { id: 'Falkland Islands', label: 'Falkland Islands' },
  { id: 'Faroe Islands', label: 'Faroe Islands' },
  { id: 'Fiji', label: 'Fiji' },
  { id: 'Finland', label: 'Finland' },
  { id: 'France', label: 'France' },
  { id: 'French Guiana', label: 'French Guiana' },
  { id: 'French Polynesia', label: 'French Polynesia' },
  {
    id: 'French Southern and Antarctic Lands',
    label: 'French Southern and Antarctic Lands',
  },
  { id: 'Gabon', label: 'Gabon' },
  { id: 'Gambia', label: 'Gambia' },
  { id: 'Georgia', label: 'Georgia' },
  { id: 'Germany', label: 'Germany' },
  { id: 'Ghana', label: 'Ghana' },
  { id: 'Gibraltar', label: 'Gibraltar' },
  { id: 'Greece', label: 'Greece' },
  { id: 'Greenland', label: 'Greenland' },
  { id: 'Grenada', label: 'Grenada' },
  { id: 'Guadeloupe', label: 'Guadeloupe' },
  { id: 'Guam', label: 'Guam' },
  { id: 'Guatemala', label: 'Guatemala' },
  { id: 'Guernsey', label: 'Guernsey' },
  { id: 'Guinea', label: 'Guinea' },
  { id: 'Guinea-Bissau', label: 'Guinea-Bissau' },
  { id: 'Guyana', label: 'Guyana' },
  { id: 'Haiti', label: 'Haiti' },
  {
    id: 'Heard Island and McDonald Islands',
    label: 'Heard Island and McDonald Islands',
  },
  { id: 'Honduras', label: 'Honduras' },
  { id: 'Hong Kong', label: 'Hong Kong' },
  { id: 'Hungary', label: 'Hungary' },
  { id: 'Iceland', label: 'Iceland' },
  { id: 'India', label: 'India' },
  { id: 'Indonesia', label: 'Indonesia' },
  { id: 'Iran', label: 'Iran' },
  { id: 'Iraq', label: 'Iraq' },
  { id: 'Ireland', label: 'Ireland' },
  { id: 'Isle of Man', label: 'Isle of Man' },
  { id: 'Israel', label: 'Israel' },
  { id: 'Italy', label: 'Italy' },
  { id: 'Jamaica', label: 'Jamaica' },
  { id: 'Japan', label: 'Japan' },
  { id: 'Jersey', label: 'Jersey' },
  { id: 'Jordan', label: 'Jordan' },
  { id: 'Kazakhstan', label: 'Kazakhstan' },
  { id: 'Kenya', label: 'Kenya' },
  { id: 'Kiribati', label: 'Kiribati' },
  { id: 'Kosovo', label: 'Kosovo' },
  { id: 'Kuwait', label: 'Kuwait' },
  { id: 'Kyrgyzstan', label: 'Kyrgyzstan' },
  { id: 'Laos', label: 'Laos' },
  { id: 'Latvia', label: 'Latvia' },
  { id: 'Lebanon', label: 'Lebanon' },
  { id: 'Lesotho', label: 'Lesotho' },
  { id: 'Liberia', label: 'Liberia' },
  { id: 'Libya', label: 'Libya' },
  { id: 'Liechtenstein', label: 'Liechtenstein' },
  { id: 'Lithuania', label: 'Lithuania' },
  { id: 'Luxembourg', label: 'Luxembourg' },
  { id: 'Macau', label: 'Macau' },
  { id: 'Macedonia (FYROM)', label: 'Macedonia (FYROM)' },
  { id: 'Madagascar', label: 'Madagascar' },
  { id: 'Malawi', label: 'Malawi' },
  { id: 'Malaysia', label: 'Malaysia' },
  { id: 'Maldives', label: 'Maldives' },
  { id: 'Mali', label: 'Mali' },
  { id: 'Malta', label: 'Malta' },
  { id: 'Marshall Islands', label: 'Marshall Islands' },
  { id: 'Martinique', label: 'Martinique' },
  { id: 'Mauritania', label: 'Mauritania' },
  { id: 'Mauritius', label: 'Mauritius' },
  { id: 'Mayotte', label: 'Mayotte' },
  { id: 'Mexico', label: 'Mexico' },
  { id: 'Micronesia', label: 'Micronesia' },
  { id: 'Moldova', label: 'Moldova' },
  { id: 'Monaco', label: 'Monaco' },
  { id: 'Mongolia', label: 'Mongolia' },
  { id: 'Montenegro', label: 'Montenegro' },
  { id: 'Montserrat', label: 'Montserrat' },
  { id: 'Morocco', label: 'Morocco' },
  { id: 'Mozambique', label: 'Mozambique' },
  { id: 'Myanmar (Burma)', label: 'Myanmar (Burma)' },
  { id: 'Namibia', label: 'Namibia' },
  { id: 'Nauru', label: 'Nauru' },
  { id: 'Nepal', label: 'Nepal' },
  { id: 'Netherlands', label: 'Netherlands' },
  { id: 'Netherlands Antilles', label: 'Netherlands Antilles' },
  { id: 'New Caledonia', label: 'New Caledonia' },
  { id: 'New Zealand', label: 'New Zealand' },
  { id: 'Nicaragua', label: 'Nicaragua' },
  { id: 'Niger', label: 'Niger' },
  { id: 'Nigeria', label: 'Nigeria' },
  { id: 'Niue', label: 'Niue' },
  { id: 'Norfolk Island', label: 'Norfolk Island' },
  { id: 'North Korea', label: 'North Korea' },
  { id: 'Northern Mariana Islands', label: 'Northern Mariana Islands' },
  { id: 'Norway', label: 'Norway' },
  { id: 'Oman', label: 'Oman' },
  { id: 'Pakistan', label: 'Pakistan' },
  { id: 'Palau', label: 'Palau' },
  { id: 'Palestine', label: 'Palestine' },
  { id: 'Panama', label: 'Panama' },
  { id: 'Papua New Guinea', label: 'Papua New Guinea' },
  { id: 'Paraguay', label: 'Paraguay' },
  { id: 'Peru', label: 'Peru' },
  { id: 'Philippines', label: 'Philippines' },
  { id: 'Pitcairn Islands', label: 'Pitcairn Islands' },
  { id: 'Poland', label: 'Poland' },
  { id: 'Portugal', label: 'Portugal' },
  { id: 'Puerto Rico', label: 'Puerto Rico' },
  { id: 'Qatar', label: 'Qatar' },
  { id: 'Réunion', label: 'Réunion' },
  { id: 'Romania', label: 'Romania' },
  { id: 'Russia', label: 'Russia' },
  { id: 'Rwanda', label: 'Rwanda' },
  { id: 'Saint Barthélemy', label: 'Saint Barthélemy' },
  { id: 'Saint Helena', label: 'Saint Helena' },
  { id: 'Saint Kitts and Nevis', label: 'Saint Kitts and Nevis' },
  { id: 'Saint Lucia', label: 'Saint Lucia' },
  { id: 'Saint Martin', label: 'Saint Martin' },
  { id: 'Saint Pierre and Miquelon', label: 'Saint Pierre and Miquelon' },
  {
    id: 'Saint Vincent and the Grenadines',
    label: 'Saint Vincent and the Grenadines',
  },
  { id: 'Samoa', label: 'Samoa' },
  { id: 'San Marino', label: 'San Marino' },
  { id: 'Sao Tome and Principe', label: 'Sao Tome and Principe' },
  { id: 'Saudi Arabia', label: 'Saudi Arabia' },
  { id: 'Senegal', label: 'Senegal' },
  { id: 'Serbia', label: 'Serbia' },
  { id: 'Seychelles', label: 'Seychelles' },
  { id: 'Sierra Leone', label: 'Sierra Leone' },
  { id: 'Singapore', label: 'Singapore' },
  { id: 'Sint Maarten', label: 'Sint Maarten' },
  { id: 'Slovakia', label: 'Slovakia' },
  { id: 'Slovenia', label: 'Slovenia' },
  { id: 'Solomon Islands', label: 'Solomon Islands' },
  { id: 'Somalia', label: 'Somalia' },
  { id: 'South Africa', label: 'South Africa' },
  {
    id: 'South Georgia and the South Sandwich Islands',
    label: 'South Georgia and the South Sandwich Islands',
  },
  { id: 'South Korea', label: 'South Korea' },
  { id: 'South Sudan', label: 'South Sudan' },
  { id: 'Spain', label: 'Spain' },
  { id: 'Sri Lanka', label: 'Sri Lanka' },
  { id: 'Sudan', label: 'Sudan' },
  { id: 'Suriname', label: 'Suriname' },
  { id: 'Svalbard and Jan Mayen', label: 'Svalbard and Jan Mayen' },
  { id: 'Swaziland', label: 'Swaziland' },
  { id: 'Sweden', label: 'Sweden' },
  { id: 'Switzerland', label: 'Switzerland' },
  { id: 'Syria', label: 'Syria' },
  { id: 'Taiwan', label: 'Taiwan' },
  { id: 'Tajikistan', label: 'Tajikistan' },
  { id: 'Tanzania', label: 'Tanzania' },
  { id: 'Thailand', label: 'Thailand' },
  { id: 'Togo', label: 'Togo' },
  { id: 'Tokelau', label: 'Tokelau' },
  { id: 'Tonga', label: 'Tonga' },
  { id: 'Trinidad and Tobago', label: 'Trinidad and Tobago' },
  { id: 'Tunisia', label: 'Tunisia' },
  { id: 'Türkiye', label: 'Türkiye' },
  { id: 'Turkmenistan', label: 'Turkmenistan' },
  { id: 'Turks and Caicos Islands', label: 'Turks and Caicos Islands' },
  { id: 'Tuvalu', label: 'Tuvalu' },
  { id: 'U.S. Virgin Islands', label: 'U.S. Virgin Islands' },
  { id: 'Uganda', label: 'Uganda' },
  { id: 'Ukraine', label: 'Ukraine' },
  { id: 'United Arab Emirates', label: 'United Arab Emirates' },
  { id: 'United Kingdom', label: 'United Kingdom' },
  { id: 'United States', label: 'United States' },
  {
    id: 'United States Minor Outlying Islands',
    label: 'United States Minor Outlying Islands',
  },
  { id: 'Uruguay', label: 'Uruguay' },
  { id: 'Uzbekistan', label: 'Uzbekistan' },
  { id: 'Vanuatu', label: 'Vanuatu' },
  { id: 'Vatican City', label: 'Vatican City' },
  { id: 'Venezuela', label: 'Venezuela' },
  { id: 'Vietnam', label: 'Vietnam' },
  { id: 'Wallis and Futuna', label: 'Wallis and Futuna' },
  { id: 'Western Sahara', label: 'Western Sahara' },
  { id: 'Yemen', label: 'Yemen' },
  { id: 'Zambia', label: 'Zambia' },
  { id: 'Zimbabwe', label: 'Zimbabwe' },
];

export const countriesDe = [
  { id: 'Afghanistan', label: 'Afghanistan' },
  { id: 'Åland Islands', label: 'Ålandinseln' },
  { id: 'Albania', label: 'Albanien' },
  { id: 'Algeria', label: 'Algerien' },
  { id: 'American Samoa', label: 'Amerikanisch-Samoa' },
  { id: 'Andorra', label: 'Andorra' },
  { id: 'Angola', label: 'Angola' },
  { id: 'Anguilla', label: 'Anguilla' },
  { id: 'Antarctica', label: 'Antarktika' },
  { id: 'Antigua and Barbuda', label: 'Antigua und Barbuda' },
  { id: 'Argentina', label: 'Argentinien' },
  { id: 'Armenia', label: 'Armenien' },
  { id: 'Aruba', label: 'Aruba' },
  { id: 'Asia/Pacific Region', label: 'Asien/Pazifik-Region' },
  { id: 'Australia', label: 'Australien' },
  { id: 'Austria', label: 'Österreich' },
  { id: 'Azerbaijan', label: 'Aserbaidschan' },
  { id: 'Bahamas', label: 'Bahamas' },
  { id: 'Bahrain', label: 'Bahrain' },
  { id: 'Bangladesh', label: 'Bangladesch' },
  { id: 'Barbados', label: 'Barbados' },
  { id: 'Belarus', label: 'Weißrussland' },
  { id: 'Belgium', label: 'Belgien' },
  { id: 'Belize', label: 'Belize' },
  { id: 'Benin', label: 'Benin' },
  { id: 'Bermuda', label: 'Bermuda' },
  { id: 'Bhutan', label: 'Bhutan' },
  { id: 'Bolivia', label: 'Bolivien' },
  { id: 'Bosnia and Herzegovina', label: 'Bosnien und Herzegowina' },
  { id: 'Botswana', label: 'Botswana' },
  { id: 'Bouvet Island', label: 'Bouvetinsel' },
  { id: 'Brazil', label: 'Brasilien' },
  {
    id: 'British Indian Ocean Territory',
    label: 'Britisches Territorium im Indischen Ozean',
  },
  { id: 'British Virgin Islands', label: 'Britische Jungferninseln' },
  { id: 'Brunei', label: 'Brunei' },
  { id: 'Bulgaria', label: 'Bulgarien' },
  { id: 'Burkina Faso', label: 'Burkina Faso' },
  { id: 'Burundi', label: 'Burundi' },
  { id: 'Cambodia', label: 'Kambodscha' },
  { id: 'Cameroon', label: 'Kamerun' },
  { id: 'Canada', label: 'Kanada' },
  { id: 'Canary Islands', label: 'Kanarische Inseln' },
  { id: 'Cape Verde', label: 'Kap Verde' },
  { id: 'Caribbean Netherlands', label: 'Karibische Niederlande' },
  { id: 'Cayman Islands', label: 'Kaimaninseln' },
  { id: 'Central African Republic', label: 'Zentralafrikanische Republik' },
  { id: 'Chad', label: 'Tschad' },
  { id: 'Chile', label: 'Chile' },
  { id: 'China', label: 'China' },
  { id: 'Christmas Island', label: 'Weihnachtsinsel' },
  { id: 'Cocos (Keeling) Islands', label: 'Kokosinseln (Keelinginseln)' },
  { id: 'Colombia', label: 'Kolumbien' },
  { id: 'Comoros', label: 'Komoren' },
  { id: 'Congo', label: 'Kongo' },
  { id: 'Cook Islands', label: 'Cookinseln' },
  { id: 'Costa Rica', label: 'Costa Rica' },
  { id: "Cote d'Ivoire", label: "Côte d'Ivoire (Elfenbeinküste)" },
  { id: 'Croatia', label: 'Kroatien' },
  { id: 'Cuba', label: 'Kuba' },
  { id: 'Curaçao', label: 'Curaçao' },
  { id: 'Cyprus', label: 'Zypern' },
  { id: 'Czech Republic', label: 'Tschechische Republik' },
  {
    id: 'Democratic Republic of the Congo',
    label: 'Demokratische Republik Kongo',
  },
  { id: 'Denmark', label: 'Dänemark' },
  { id: 'Djibouti', label: 'Dschibuti' },
  { id: 'Dominica', label: 'Dominica' },
  { id: 'Dominican Republic', label: 'Dominikanische Republik' },
  { id: 'East Timor', label: 'Osttimor' },
  { id: 'Ecuador', label: 'Ecuador' },
  { id: 'Egypt', label: 'Ägypten' },
  { id: 'El Salvador', label: 'El Salvador' },
  { id: 'Equatorial Guinea', label: 'Äquatorialguinea' },
  { id: 'Eritrea', label: 'Eritrea' },
  { id: 'Estonia', label: 'Estland' },
  { id: 'Ethiopia', label: 'Äthiopien' },
  { id: 'Europe', label: 'Europa' },
  { id: 'Falkland Islands', label: 'Falklandinseln' },
  { id: 'Faroe Islands', label: 'Färöer-Inseln' },
  { id: 'Fiji', label: 'Fidschi' },
  { id: 'Finland', label: 'Finnland' },
  { id: 'France', label: 'Frankreich' },
  { id: 'French Guiana', label: 'Französisch-Guayana' },
  { id: 'French Polynesia', label: 'Französisch-Polynesien' },
  {
    id: 'French Southern and Antarctic Lands',
    label: 'Französische Süd- und Antarktisgebiete',
  },
  { id: 'Gabon', label: 'Gabun' },
  { id: 'Gambia', label: 'Gambia' },
  { id: 'Georgia', label: 'Georgien' },
  { id: 'Germany', label: 'Deutschland' },
  { id: 'Ghana', label: 'Ghana' },
  { id: 'Gibraltar', label: 'Gibraltar' },
  { id: 'Greece', label: 'Griechenland' },
  { id: 'Greenland', label: 'Grönland' },
  { id: 'Grenada', label: 'Grenada' },
  { id: 'Guadeloupe', label: 'Guadeloupe' },
  { id: 'Guam', label: 'Guam' },
  { id: 'Guatemala', label: 'Guatemala' },
  { id: 'Guernsey', label: 'Guernsey' },
  { id: 'Guinea', label: 'Guinea' },
  { id: 'Guinea-Bissau', label: 'Guinea-Bissau' },
  { id: 'Guyana', label: 'Guyana' },
  { id: 'Haiti', label: 'Haiti' },
  {
    id: 'Heard Island and McDonald Islands',
    label: 'Heard- und McDonald-Inseln',
  },
  { id: 'Honduras', label: 'Honduras' },
  { id: 'Hong Kong', label: 'Hongkong' },
  { id: 'Hungary', label: 'Ungarn' },
  { id: 'Iceland', label: 'Island' },
  { id: 'India', label: 'Indien' },
  { id: 'Indonesia', label: 'Indonesien' },
  { id: 'Iran', label: 'Iran' },
  { id: 'Iraq', label: 'Irak' },
  { id: 'Ireland', label: 'Irland' },
  { id: 'Isle of Man', label: 'Isle of Man' },
  { id: 'Israel', label: 'Israel' },
  { id: 'Italy', label: 'Italien' },
  { id: 'Jamaica', label: 'Jamaika' },
  { id: 'Japan', label: 'Japan' },
  { id: 'Jersey', label: 'Jersey' },
  { id: 'Jordan', label: 'Jordanien' },
  { id: 'Kazakhstan', label: 'Kasachstan' },
  { id: 'Kenya', label: 'Kenia' },
  { id: 'Kiribati', label: 'Kiribati' },
  { id: 'Kosovo', label: 'Kosovo' },
  { id: 'Kuwait', label: 'Kuwait' },
  { id: 'Kyrgyzstan', label: 'Kirgisistan' },
  { id: 'Laos', label: 'Laos' },
  { id: 'Latvia', label: 'Lettland' },
  { id: 'Lebanon', label: 'Libanon' },
  { id: 'Lesotho', label: 'Lesotho' },
  { id: 'Liberia', label: 'Liberia' },
  { id: 'Libya', label: 'Libyen' },
  { id: 'Liechtenstein', label: 'Liechtenstein' },
  { id: 'Lithuania', label: 'Litauen' },
  { id: 'Luxembourg', label: 'Luxemburg' },
  { id: 'Macau', label: 'Macau' },
  { id: 'Macedonia (FYROM)', label: 'Mazedonien (EJR)' },
  { id: 'Madagascar', label: 'Madagaskar' },
  { id: 'Malawi', label: 'Malawi' },
  { id: 'Malaysia', label: 'Malaysia' },
  { id: 'Maldives', label: 'Malediven' },
  { id: 'Mali', label: 'Mali' },
  { id: 'Malta', label: 'Malta' },
  { id: 'Marshall Islands', label: 'Marshallinseln' },
  { id: 'Martinique', label: 'Martinique' },
  { id: 'Mauritania', label: 'Mauretanien' },
  { id: 'Mauritius', label: 'Mauritius' },
  { id: 'Mayotte', label: 'Mayotte' },
  { id: 'Mexico', label: 'Mexiko' },
  { id: 'Micronesia', label: 'Mikronesien' },
  { id: 'Moldova', label: 'Moldawien' },
  { id: 'Monaco', label: 'Monaco' },
  { id: 'Mongolia', label: 'Mongolei' },
  { id: 'Montenegro', label: 'Montenegro' },
  { id: 'Montserrat', label: 'Montserrat' },
  { id: 'Morocco', label: 'Marokko' },
  { id: 'Mozambique', label: 'Mosambik' },
  { id: 'Myanmar (Burma)', label: 'Myanmar (Burma)' },
  { id: 'Namibia', label: 'Namibia' },
  { id: 'Nauru', label: 'Nauru' },
  { id: 'Nepal', label: 'Nepal' },
  { id: 'Netherlands', label: 'Niederlande' },
  { id: 'Netherlands Antilles', label: 'Niederländische Antillen' },
  { id: 'New Caledonia', label: 'Neukaledonien' },
  { id: 'New Zealand', label: 'Neuseeland' },
  { id: 'Nicaragua', label: 'Nicaragua' },
  { id: 'Niger', label: 'Niger' },
  { id: 'Nigeria', label: 'Nigeria' },
  { id: 'Niue', label: 'Niue' },
  { id: 'Norfolk Island', label: 'Norfolkinsel' },
  { id: 'North Korea', label: 'Nordkorea' },
  { id: 'Northern Mariana Islands', label: 'Nördliche Marianen' },
  { id: 'Norway', label: 'Norwegen' },
  { id: 'Oman', label: 'Oman' },
  { id: 'Pakistan', label: 'Pakistan' },
  { id: 'Palau', label: 'Palau' },
  { id: 'Palestine', label: 'Palästina' },
  { id: 'Panama', label: 'Panama' },
  { id: 'Papua New Guinea', label: 'Papua-Neuguinea' },
  { id: 'Paraguay', label: 'Paraguay' },
  { id: 'Peru', label: 'Peru' },
  { id: 'Philippines', label: 'Philippinen' },
  { id: 'Pitcairn Islands', label: 'Pitcairninseln' },
  { id: 'Poland', label: 'Polen' },
  { id: 'Portugal', label: 'Portugal' },
  { id: 'Puerto Rico', label: 'Puerto Rico' },
  { id: 'Qatar', label: 'Katar' },
  { id: 'Réunion', label: 'Réunion' },
  { id: 'Romania', label: 'Rumänien' },
  { id: 'Russia', label: 'Russland' },
  { id: 'Rwanda', label: 'Ruanda' },
  { id: 'Saint Barthélemy', label: 'Saint-Barthélemy' },
  { id: 'Saint Helena', label: 'St. Helena' },
  { id: 'Saint Kitts and Nevis', label: 'St. Kitts und Nevis' },
  { id: 'Saint Lucia', label: 'St. Lucia' },
  { id: 'Saint Martin', label: 'Saint-Martin' },
  { id: 'Saint Pierre and Miquelon', label: 'St. Pierre und Miquelon' },
  {
    id: 'Saint Vincent and the Grenadines',
    label: 'St. Vincent und die Grenadinen',
  },
  { id: 'Samoa', label: 'Samoa' },
  { id: 'San Marino', label: 'San Marino' },
  { id: 'Sao Tome and Principe', label: 'São Tomé und Príncipe' },
  { id: 'Saudi Arabia', label: 'Saudi-Arabien' },
  { id: 'Senegal', label: 'Senegal' },
  { id: 'Serbia', label: 'Serbien' },
  { id: 'Seychelles', label: 'Seychellen' },
  { id: 'Sierra Leone', label: 'Sierra Leone' },
  { id: 'Singapore', label: 'Singapur' },
  { id: 'Sint Maarten', label: 'Sint Maarten' },
  { id: 'Slovakia', label: 'Slowakei' },
  { id: 'Slovenia', label: 'Slowenien' },
  { id: 'Solomon Islands', label: 'Salomonen' },
  { id: 'Somalia', label: 'Somalia' },
  { id: 'South Africa', label: 'Südafrika' },
  {
    id: 'South Georgia and the South Sandwich Islands',
    label: 'Südgeorgien und die Südlichen Sandwichinseln',
  },
  { id: 'South Korea', label: 'Südkorea' },
  { id: 'South Sudan', label: 'Südsudan' },
  { id: 'Spain', label: 'Spanien' },
  { id: 'Sri Lanka', label: 'Sri Lanka' },
  { id: 'Sudan', label: 'Sudan' },
  { id: 'Suriname', label: 'Suriname' },
  { id: 'Svalbard and Jan Mayen', label: 'Sval' },
  { id: 'Swaziland', label: 'Eswatini' },
  { id: 'Sweden', label: 'Schweden' },
  { id: 'Switzerland', label: 'Schweiz' },
  { id: 'Syria', label: 'Syrien' },
  { id: 'Taiwan', label: 'Taiwan' },
  { id: 'Tajikistan', label: 'Tadschikistan' },
  { id: 'Tanzania', label: 'Tansania' },
  { id: 'Thailand', label: 'Thailand' },
  { id: 'Togo', label: 'Togo' },
  { id: 'Tokelau', label: 'Tokelau' },
  { id: 'Tonga', label: 'Tonga' },
  { id: 'Trinidad and Tobago', label: 'Trinidad und Tobago' },
  { id: 'Tunisia', label: 'Tunesien' },
  { id: 'Türkiye', label: 'Türkei' },
  { id: 'Turkmenistan', label: 'Turkmenistan' },
  { id: 'Turks and Caicos Islands', label: 'Turks- und Caicosinseln' },
  { id: 'Tuvalu', label: 'Tuvalu' },
  { id: 'U.S. Virgin Islands', label: 'Amerikanische Jungferninseln' },
  { id: 'Uganda', label: 'Uganda' },
  { id: 'Ukraine', label: 'Ukraine' },
  { id: 'United Arab Emirates', label: 'Vereinigte Arabische Emirate' },
  { id: 'United Kingdom', label: 'Vereinigtes Königreich' },
  { id: 'United States', label: 'Vereinigte Staaten' },
  {
    id: 'United States Minor Outlying Islands',
    label: 'Amerikanische Überseeinseln',
  },
  { id: 'Uruguay', label: 'Uruguay' },
  { id: 'Uzbekistan', label: 'Usbekistan' },
  { id: 'Vanuatu', label: 'Vanuatu' },
  { id: 'Vatican City', label: 'Vatikanstadt' },
  { id: 'Venezuela', label: 'Venezuela' },
  { id: 'Vietnam', label: 'Vietnam' },
  { id: 'Wallis and Futuna', label: 'Wallis und Futuna' },
  { id: 'Western Sahara', label: 'Westsahara' },
  { id: 'Yemen', label: 'Jemen' },
  { id: 'Zambia', label: 'Sambia' },
  { id: 'Zimbabwe', label: 'Simbabwe' },
];
