import api from 'src/helpers/apiConfig';

const urlBase = 'horses';

const getAll = (query) => api.get(`${urlBase}/`, { params: { ...query } });
const create = (data) => api.post(`${urlBase}/`, data);
const update = (id, data) => api.post(`${urlBase}/${id}?_method=PUT`, data);
const destroy = (id) => api.delete(`${urlBase}/${id}`);

const HorseService = {
  getAll,
  create,
  update,
  destroy,
};

export default HorseService;
