import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import i18n from 'i18next';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import cx from 'classnames';
import { uiCloseModal, uiShowSuccessToast } from 'src/store/actions/uiActions';
import Modal from 'src/uikit/Modal';
import { Button, ButtonLink } from 'src/uikit/Button';
import OfflineNotice from 'src/uikit/OfflineNotice';
import TextInput from 'src/uikit/TextInput';
import styles from './ExportModal.module.scss';

const ExportModal = ({ modal }) => {
  const dispatch = useDispatch();
  const currentLang = i18n.language;
  const { t } = useTranslation('messages');
  const { id, horseName, date } = modal;
  const { mode } = useSelector((state) => state.uiReducer);
  const [showShare, setShowShare] = useState(false);

  const handleClose = () => dispatch(uiCloseModal());

  const pdfLink = `${process.env.REACT_APP_API_BASE}/${currentLang}/reports/${id}/pdf`;

  const previewLink = `${process.env.REACT_APP_BASE}/reports/${id}/preview`;

  const shareRef = useRef();

  const handleShare = () => {
    setShowShare(true);

    setTimeout(() => {
      shareRef.current.select();

      try {
        document.execCommand('copy');
        dispatch(uiShowSuccessToast(t('copyMsg')));
      } catch (err) {}
    }, 0);
  };

  return (
    <Modal onClose={handleClose} size="sm">
      <div className={styles.wrapper}>
        <div className={styles.header}>
          <i className={cx('icon-horse', styles.icon)} />
          <h3 className={styles.heading}>{horseName}</h3>
          <div className={styles.dates}>
            {`${moment(date).format('DD.MM.YYYY')} - ${moment(date)
              .add(41, 'days')
              .format('DD.MM.YYYY')}`}
          </div>
        </div>

        {mode === 'offline' ? (
          <div className={styles.body}>
            <OfflineNotice />
          </div>
        ) : (
          <>
            <div className={styles.body}>
              <div className={styles.innerHeading}>
                {t('exportModal.successMsg')}
              </div>
              <div className={styles.fileName}>{`${horseName.replace(
                /\s/g,
                '_',
              )}_${moment(date).format('YYYYMMDD')}-${moment(date)
                .add(41, 'days')
                .format('YYYYMMDD')}.pdf`}</div>
            </div>

            <div
              className="mb-8"
              style={{ display: showShare ? 'block' : 'none' }}
            >
              <TextInput
                inputRef={shareRef}
                defaultValue={previewLink}
                readOnly
              />
            </div>

            <div className={styles.footer}>
              <ButtonLink
                variant="primary"
                label={t('actions.downloadLabel')}
                to={pdfLink}
                target="_blank"
              />
              <Button
                type="button"
                variant="secondary"
                label={t('actions.shareLabel')}
                onClick={handleShare}
              />
            </div>
          </>
        )}
      </div>
    </Modal>
  );
};

export default ExportModal;

ExportModal.propTypes = {
  modal: PropTypes.shape({
    type: PropTypes.string,
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    horseName: PropTypes.string,
    date: PropTypes.string,
  }),
};

ExportModal.defaultProps = {
  modal: {},
};
