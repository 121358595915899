import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import styles from './Button.module.scss';

export const ButtonIcon = ({
  type,
  title,
  icon,
  variant,
  shadow,
  onClick,
  ...rest
}) => (
  <button
    type={type}
    onClick={onClick}
    className={cx(styles.btnIcon, styles[variant], shadow && styles.hasShadow)}
    title={title}
    {...rest}
  >
    <i className={icon} />
  </button>
);

ButtonIcon.propTypes = {
  type: PropTypes.string,
  title: PropTypes.string,
  icon: PropTypes.string,
  variant: PropTypes.oneOf([
    'primary',
    'secondary',
    'primary-outline',
    'secondary-outline',
  ]),
  shadow: PropTypes.bool,
  onClick: PropTypes.func,
};

ButtonIcon.defaultProps = {
  type: 'button',
  title: '',
  icon: '',
  variant: 'primary',
  shadow: false,
  onClick: null,
};
