import { useState, useRef } from 'react';

const useReportScroll = () => {
  const [scrolled, setScrolled] = useState(0);
  const wrapperRef = useRef();

  const scrollProgress = () => {
    if (!wrapperRef || !wrapperRef.current) return;

    const scrollPx = wrapperRef.current.scrollTop;

    const winHeightPx =
      wrapperRef.current.scrollHeight - wrapperRef.current.clientHeight;

    const nextScrolled = (scrollPx / winHeightPx) * 100;

    setScrolled(nextScrolled);
  };

  return {
    scrolled,
    wrapperRef,
    scrollProgress,
  };
};

export default useReportScroll;
