import React from 'react';
import styles from './PageLoader.module.scss';

const PageLoader = () => (
  <div className={styles.wrapper}>
    <div className={styles.spinner}>Loading...</div>
  </div>
);

export default PageLoader;
