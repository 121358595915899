import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Cookies } from 'react-cookie';
import { Button } from 'src/uikit/Button';
import SwitchToggle from 'src/uikit/SwitchToggle';
import LanguageSelect from 'src/components/LanguageSelect';
import Accordion from './Accordion';
import styles from './CookieNotice.module.scss';

const cookieLifetime = 3600 * 8760;

const CookieNotice = () => {
  const { t } = useTranslation('messages');
  const cookies = new Cookies();
  const [show, setShow] = useState(false);
  const [consent, setConsent] = useState(false);

  const handleGrantGa = () => {
    if (typeof window !== 'undefined' && typeof window.gtag !== 'undefined') {
      const { gtag } = window;

      gtag('consent', 'update', {
        ad_storage: 'granted',
        analytics_storage: 'granted',
      });
    }
  };

  const handleClose = (nextConsent) => {
    cookies.set('_ck', true, { path: '/', maxAge: cookieLifetime });

    if (nextConsent)
      cookies.set('_ck_consent', true, { path: '/', maxAge: cookieLifetime });

    setShow(false);
  };

  const handleReject = () => handleClose(false);

  const handleAcceptAll = () => {
    handleGrantGa();
    handleClose(true);
  };

  const handleSave = () => {
    if (consent) handleGrantGa();

    handleClose(consent);
  };

  // useEffect(() => {
  //   if (
  //     navigator.standalone ||
  //     window.matchMedia('(display-mode: standalone)').matches
  //   )
  //     return;
  //
  //   const ck = cookies.get('_ck');
  //   const ckConsent = cookies.get('_ck_consent');
  //
  //   if (!ck) setShow(true);
  //
  //   if (ckConsent) handleGrantGa();
  // }, [cookies]);

  return show ? (
    <div className={styles.wrapper}>
      <div className={styles.overlay} />

      <div className={styles.inner}>
        <div className={styles.content}>
          <div className={styles.header}>
            <h3 className={styles.heading}>{t('cookies.title')}</h3>
            <LanguageSelect />
          </div>

          <div className={styles.dscr}>
            <div
              className={styles.text}
              dangerouslySetInnerHTML={{
                __html: t('cookies.discalimer', {
                  interpolation: { escapeValue: false },
                }),
              }}
            />
          </div>

          <div className={styles.actions}>
            <Button
              type="button"
              variant="primary"
              label={t('cookies.accept')}
              onClick={handleAcceptAll}
            />

            <Button
              type="button"
              variant="secondary-outline"
              label={t('cookies.reject')}
              onClick={handleReject}
            />
          </div>

          <Accordion title={t('cookies.settingsTitle')}>
            <div className={styles.accordionRow}>
              <label className={styles.checkboxItem}>
                <span className={styles.checkboxLabel}>
                  {t('cookies.required')}
                </span>
                <SwitchToggle name="required" defaultChecked disabled />
              </label>

              <div className={styles.checkboxDscr}>
                {t('cookies.requiredDscr')}
              </div>
            </div>

            <div className={styles.accordionRow}>
              <label className={styles.checkboxItem}>
                <span className={styles.checkboxLabel}>
                  {t('cookies.analytics')}
                </span>
                <SwitchToggle
                  name="analytics"
                  onChange={(e) => setConsent(e.target.checked)}
                />
              </label>

              <div className={styles.checkboxDscr}>
                {t('cookies.analyticsDscr')}
              </div>
            </div>

            <div className={styles.accordionRow}>
              <Button
                type="button"
                variant="primary"
                label={t('cookies.save')}
                onClick={handleSave}
              />
            </div>
          </Accordion>
        </div>
      </div>
    </div>
  ) : null;
};

export default CookieNotice;
