import {
  AUTH_SET_INITIALIZED,
  AUTH_SET_USER,
  AUTH_LOGIN_USER,
  AUTH_REGISTER_USER,
  AUTH_LOGOUT_USER,
  AUTH_SET_ERRORS,
  AUTH_RESET_ERRORS,
  USERS_UPDATE,
} from 'src/store/actions/actionTypes';

const initialState = {
  isInitialized: false,
  isAuthenticated: false,
  authUser: null,
  errors: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case AUTH_SET_INITIALIZED:
      return {
        ...state,
        isInitialized: true,
      };

    case AUTH_SET_USER:
    case AUTH_LOGIN_USER:
    case AUTH_REGISTER_USER:
      return {
        ...state,
        isAuthenticated: true,
        authUser: action.payload.user,
        errors: null,
      };

    case AUTH_LOGOUT_USER:
      return {
        ...initialState,
        isInitialized: true,
      };

    case AUTH_SET_ERRORS:
      return {
        ...state,
        errors: {
          status: action.payload?.status || '',
          message: action.payload?.data?.message,
          errors: action.payload?.data?.errors,
        },
      };

    case AUTH_RESET_ERRORS:
      return {
        ...state,
        errors: null,
      };

    case USERS_UPDATE:
      return {
        ...state,
        authUser: action.payload.user,
      };

    default:
      return state;
  }
};

export default reducer;
