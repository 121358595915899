import { combineReducers } from 'redux';
import uiReducer from './uiReducer';
import authReducer from './authReducer';
import horseReducer from './horseReducer';
import reportReducer from './reportReducer';
import recordReducer from './recordReducer';

const reducers = combineReducers({
  uiReducer,
  authReducer,
  horseReducer,
  reportReducer,
  recordReducer,
});

export default reducers;
