import React from 'react';
import PropTypes from 'prop-types';
import TopBar from 'src/components/TopBar';
import Modals from 'src/components/Modals';
import CookieNotice from 'src/uikit/CookieNotice';
import OfflineMsg from 'src/uikit/OfflineMsg';
import SuccessToast from 'src/uikit/SuccessToast';
import bgLogo from 'src/assets/img/bgLogo.svg';
import styles from './LoginLayout.module.scss';

export const LoginLayout = ({ children }) => (
  <div className={styles.wrapper} style={{ backgroundImage: `url(${bgLogo})` }}>
    <OfflineMsg />
    <SuccessToast />
    <TopBar />
    <div className={styles.inner}>{children}</div>
    <Modals />
    <CookieNotice />
  </div>
);

LoginLayout.propTypes = {
  children: PropTypes.node,
};

LoginLayout.defaultProps = {
  children: null,
};
