import React from 'react';
import PropTypes from 'prop-types';
import styles from './HorseList.module.scss';

const HorseList = ({ children }) => (
  <div className={styles.wrapper}>{children}</div>
);

export default HorseList;

HorseList.propTypes = {
  children: PropTypes.node,
};

HorseList.defaultProps = {
  children: null,
};
