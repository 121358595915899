import { useEffect, useState } from 'react';

const PullToRefresh = () => {
  const [startPoint, setStartPoint] = useState(0);
  const [pullChange, setPullChange] = useState();

  const initLoading = () => {
    setTimeout(() => {
      window.location.reload();
    }, 300);
  };

  const pullStart = (e) => {
    const { screenY } = e.targetTouches[0];

    setStartPoint(screenY);
  };

  const pull = (e) => {
    const touch = e.targetTouches[0];
    const { screenY } = touch;

    const pullLength =
      startPoint < screenY ? Math.abs(screenY - startPoint) : 0;

    setPullChange(pullLength);
  };

  const endPull = () => {
    setStartPoint(0);
    setPullChange(0);

    if (pullChange > 220) initLoading();
  };

  useEffect(() => {
    window.addEventListener('touchstart', pullStart);
    window.addEventListener('touchmove', pull);
    window.addEventListener('touchend', endPull);

    return () => {
      window.removeEventListener('touchstart', pullStart);
      window.removeEventListener('touchmove', pull);
      window.removeEventListener('touchend', endPull);
    };
  });

  return null;
};

export default PullToRefresh;
