import React from 'react';
import LanguageSelect from 'src/components/LanguageSelect';
import Nav from 'src/components/Nav';
import styles from './TopBar.module.scss';

const TopBar = () => (
  <div className={styles.wrapper}>
    <div className="container">
      <div className={styles.inner}>
        <LanguageSelect />
        <Nav />
      </div>
    </div>
  </div>
);

export default TopBar;
