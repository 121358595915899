import React from 'react';
import PropTypes from 'prop-types';
import styles from './NewReportModal.module.scss';

export const NewReportModalFooter = ({ children }) => (
  <div className={styles.footer}>
    <div className={styles.footerInner}>{children}</div>
  </div>
);

NewReportModalFooter.propTypes = {
  children: PropTypes.node,
};

NewReportModalFooter.defaultProps = {
  children: null,
};
