import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { uiShowModal } from 'src/store/actions/uiActions';
import { getOneReport, resetReports } from 'src/store/actions/reportActions';
import ReportLayout, {
  ReportHeader,
  ReportFooter,
} from 'src/layouts/ReportLayout';
import SymptomsReportList from 'src/uikit/SymptomsReportList';
import PageLoader from 'src/uikit/PageLoader';
import { Button } from 'src/uikit/Button';

const ReportPreviewPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation('messages');
  const { id } = useParams();
  const { report, errors } = useSelector((state) => state.reportReducer);
  const [isLoading, setIsLoading] = useState(true);

  const handleExportReport = () =>
    dispatch(
      uiShowModal({
        type: 'EXPORT_MODAL',
        id,
        horseName: report.horse.name,
        date: report.min_date,
      }),
    );

  useEffect(() => {
    if (!errors || !errors.status || errors.status !== 404) return;

    navigate('/not-found');
  }, [errors]);

  useEffect(() => {
    if (!report || !report.id) return;

    setIsLoading(false);
  }, [report]);

  useEffect(() => {
    if (!id) return;

    dispatch(getOneReport(id));
  }, [id]);

  useEffect(
    () => () => {
      dispatch(resetReports());
    },
    [],
  );

  return isLoading ? (
    <PageLoader />
  ) : (
    <ReportLayout>
      <ReportHeader
        title={report.horse?.name}
        subtitle={`${moment(report.min_date).format('DD.MM.YYYY')} - ${moment(
          report.min_date,
        )
          .add(41, 'days')
          .format('DD.MM.YYYY')}`}
      />

      {report && report.id && <SymptomsReportList report={report} preview />}

      <ReportFooter>
        <Button
          type="button"
          variant="secondary"
          label={t('actions.exportLabel')}
          onClick={handleExportReport}
        />
      </ReportFooter>
    </ReportLayout>
  );
};

export default ReportPreviewPage;
